import { SnackbarCloseReason } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useSelector } from "react-redux";
import { useNotification } from "../../stores/actions/StoreActions";

const Notification = (): JSX.Element => {
    const notification = useSelector((state: any) => state.notification);
    const { clearNotification } = useNotification();

    const handleClose = (_: unknown, reason?: SnackbarCloseReason) => {
        reason !== "clickaway" && clearNotification()
    }

    return (
        <Snackbar
            open={notification.open}
            // open={open}
            autoHideDuration={notification.timeout}
            onClose={handleClose}
            message={notification.message}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={{ textAlign: "center" }}
        >
            <MuiAlert onClose={handleClose} severity={notification.type} sx={{ width: '100%' }}>
                {notification.message}
            </MuiAlert>
        </Snackbar>
    );
};

export default Notification;