
import React from "react";
import { getSpotifyProducts } from '../Product/Product';
import { useNavigate } from "react-router-dom";


const SpotifyProducts: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            // height: "100vh",
        }}>
            <h1>Spotify Tools</h1>
            {getSpotifyProducts(navigate)}
        </div>
    );
};

export default SpotifyProducts;
