import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Client_ from '../../api/Client';
import constants from '../../constants/Constants';
import { useNotification } from '../../stores/actions/StoreActions';
import Link from '../Link';

interface FindEventsProps { }

const sortOptions = ['date', 'score', 'venue', 'location', ];

const defaultState = 'california';
const defaultCity = 'san francisco';


const FindEvents: React.FC<FindEventsProps> = () => {
    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const { addNotificationError, addNotificationSuccess, addNotification } = useNotification()
    const dispatch = useDispatch();

    const [playlistUrl, setPlaylistUrl] = useState('');
    const [state, setState] = useState(defaultState);
    const [city, setCity] = useState(defaultCity);
    const [eventData, setEventData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [needsSpotifyLogin, setNeedsSpotifyLogin] = useState(false);
    const [sortBy, setSortBy] = useState('date');

    useEffect(() => {
        document.title = 'Find Events';
        // Define a function to handle the Spotify authentication check
        const checkSpotifyAuth = async () => {
            const { err, res } = await Client_.client_().validateSpotifyAuth();
            if (err || !res?.success) {
                addNotificationError(err);
            } else {
                if (res?.result?.success) {
                    setNeedsSpotifyLogin(false);
                } else {
                    addNotificationError('Please login to Spotify first');
                    setNeedsSpotifyLogin(true);
                    navigate('/spotify-auth');
                }
            }
        };
        checkSpotifyAuth();
        const intervalId = setInterval(checkSpotifyAuth, 30000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);


    const findArtistEvents = (playlistUrl: string, state: string, city: string, sortBy: string) => {
        setIsLoading(true);
        Client_.client_().findArtistEvents(playlistUrl, state, city, sortBy).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
            } else {
                setEventData(res?.result?.events);
            }
        });
    }

    const setExampleData = () => {
        setPlaylistUrl(constants.exampleSpotifyUrl);
        setState(defaultState);
        setCity(defaultCity);
        setSortBy('date');
        findArtistEvents(constants.exampleSpotifyUrl, defaultState, defaultCity, 'date');
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
            <h1>Find Local Concerts and Events</h1>
            {needsSpotifyLogin &&
                <p style={{ color: 'red', fontWeight: 'bold', fontSize: 20 }}>Please login to Spotify first, click <Link to='/spotify-auth' target='_blank' rel='noreferrer'>here</Link> if not redirected automatically.</p>
            }
            <p>Enter the URL of a Spotify playlist, and see a list of nearby events from the artists in your playlist.</p>
            <p>Stuck? Right click your playlist {'>'} Share {'>'} Copy link to playlist</p>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '75%' }}>
            <TextField
                sx={{ width: '75%', m: 1 }}
                id='outlined-basic'
                label='Playlist URL'
                variant='outlined'
                value={playlistUrl}
                onChange={(event) => setPlaylistUrl(event.target.value)}
            />
                <TextField
                    sx={{ width: '75%', m: 1 }}
                    id='outlined-basic'
                    label='State'
                    variant='outlined'
                    value={state}
                    onChange={(event) => setState(event.target.value)}
                />
                <TextField
                    sx={{ width: '75%', m: 1 }}
                    id='outlined-basic'
                    label='City'
                    variant='outlined'
                    value={city}
                    onChange={(event) => setCity(event.target.value)}
                />
                <FormControl variant='outlined' sx={{ minWidth: 120, m: 1, mb: 2 }}>
                    <InputLabel >Sort</InputLabel>
                    <Select
                        value={sortBy}
                        onChange={(event) => setSortBy(event.target.value)}
                        label='Sort'
                    >
                        {sortOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                {isLoading ?
                    <CircularProgress />
                    :
                    <>
                        <Button variant='contained' color='primary' onClick={() => findArtistEvents(playlistUrl, state, city, sortBy)}>Find Events</Button>
                        <Button variant='contained' color='secondary' onClick={() => setExampleData()} sx={{ ml: 2 }}>Use Example</Button>
                    </>
                }
            </div>
            {eventData &&
                <>
                    <TableContainer component={Paper} sx={{ mt: 2, mb: 2, width: '85%' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Event Link</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Venue</TableCell>
                                    <TableCell>Location</TableCell>
                                    <TableCell>Artists</TableCell>
                                    <Tooltip title='Event score is the total count of songs in your playlist from each artist performing at the event'>
                                        <TableCell>Score</TableCell>
                                    </Tooltip>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {eventData.map((event: any) => (
                                    <TableRow key={event.id}>
                                        <TableCell>
                                            <Link to={event.link} target='_blank' rel='noreferrer'>Event Link</Link>
                                        </TableCell>
                                        <TableCell>{event.name}</TableCell>
                                        <TableCell>{event.date}</TableCell>
                                        <TableCell>{event.venue.name}</TableCell>
                                        <TableCell>{event.venue.location}</TableCell>
                                        <TableCell>
                                            {event.artistList.map((artist: any) => (
                                                <div key={artist.id}>
                                                    <Link to={artist.link} target='_blank' rel='noreferrer'>{artist.name}</Link>
                                                </div>
                                            ))}
                                        </TableCell>
                                        <TableCell>{event.score}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            }
        </div>
    );
};

export default FindEvents;