import { default as DeleteIcon } from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import 'chart.js/auto';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Client_ from '../../api/Client';
import constants from '../../constants/Constants';
import { useNotification, useUser } from '../../stores/actions/StoreActions';
import Link from '../Link';
import { DataGrid } from '@mui/x-data-grid';


interface SpotifyProps { }

const GREEN = '#00cc66';
const RED = '#990000';


const SpotifyCountArtists: React.FC<SpotifyProps> = () => {
    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const { addNotificationError, addNotificationSuccess, addNotification } = useNotification()
    const dispatch = useDispatch();

    const { forgotPassword } = useUser();

    const [playlistUrl, setPlaylistUrl] = useState("");
    const [artistTracks, setArtistTracks] = useState([]);
    const [playlistId, setPlaylistId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [needsSpotifyLogin, setNeedsSpotifyLogin] = useState(false);

    useEffect(() => {
        document.title = 'Count Artists';
        const checkSpotifyAuth = async () => {
            const { err, res } = await Client_.client_().validateSpotifyAuth();
            if (err || !res?.success) {
                addNotificationError(err);
            } else {
                if (res?.result?.success) {
                    setNeedsSpotifyLogin(false);
                } else {
                    addNotificationError('Please login to Spotify first');
                    setNeedsSpotifyLogin(true);
                    navigate('/spotify-auth');
                }
            }
        };
        checkSpotifyAuth();
        const intervalId = setInterval(checkSpotifyAuth, 30000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const countArtistTracks = (playlistUrl: string) => {
        setIsLoading(true);
        setArtistTracks([]);
        Client_.client_().countArtistTracks(playlistUrl).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                setArtistTracks(res?.result?.artist_counts);
                setPlaylistId(res?.result?.playlist_id);
            }
        });
    };

    const setExampleData = () => {
        setPlaylistUrl(constants.exampleSpotifyUrl);
        countArtistTracks(constants.exampleSpotifyUrl);
    }

    const columns = [
        { field: 'artist', headerName: 'Artist', width: 200, sortable: true },
        { field: 'count', headerName: 'Number of Songs', type: 'number', width: 200, sortable: true },
    ];


    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <h1 style={{ textAlign: 'center' }}>Count Songs Per Artist in a Playlist</h1>
            {needsSpotifyLogin &&
                <p style={{ color: RED, fontWeight: 'bold', fontSize: 20 }}>Please login to Spotify first, click <Link to='/spotify-auth' target='_blank' rel='noreferrer'>here</Link> if not redirected automatically.</p>
            }
            <p>Enter the URL of the Spotify playlist to count the tracks per artist.</p>
            <p>Stuck? Right click your playlist {'>'} Share {'>'} Copy link to playlist</p>
            <TextField
                sx={{ width: '75%', mb: 2 }}
                id="outlined-basic"
                label="Playlist URL"
                variant="outlined"
                value={playlistUrl}
                onChange={(event) => setPlaylistUrl(event.target.value)}
            />
            <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                {isLoading ?
                    <CircularProgress />
                    :
                    <>
                        <Button variant="contained" color="primary" size="medium" onClick={() => countArtistTracks(playlistUrl)}>Count Tracks</Button>
                        <Button variant='contained' color='secondary' onClick={() => setExampleData()} sx={{ ml: 2 }}>Use Example</Button>
                    </>
                }
            </div>

            {artistTracks?.length > 0 &&
                <>
                <hr style={{ width: '75%', marginTop: 20 }} />
                <Box sx={{ mt:2, width: '80%' }}>
                    <DataGrid
                        rows={artistTracks}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 20,
                                },
                            },
                        }}
                        pageSizeOptions={[5]}
                        disableRowSelectionOnClick
                    />
                </Box>
                </>
            }
        </div>
    );
};

export default SpotifyCountArtists;
