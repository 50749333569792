import TextField from '@mui/material/TextField';
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


interface ProfileProps { }

const Profile: React.FC<ProfileProps> = () => {
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);

    useEffect(() => {
        if (!user?.username) {
            navigate('/login');
        }
    }, [navigate, user]);


    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h1>{user.first_name}</h1>
            <TextField
                style={{ width: '50%', marginTop: 10 }}
                id="outlined-basic"
                label="Username"
                disabled
                variant="outlined"
                value={user.username}
            />

            <TextField
                style={{ width: '50%', marginTop: 10 }}
                id="outlined-basic"
                label="Email"
                disabled
                variant="outlined"
                value={user.email}
            />
        </div>
    );
};

export default Profile;