import { useRouteError } from "react-router-dom";

const ErrorPage: React.FC = () => {
    const error: any = useRouteError();

    console.error(error);

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            // background: "#F2F2F2"
        }}>
            <h1 style={{
                fontSize: "36px",
                fontWeight: "bold",
                // color: "#333333",
                marginBottom: "10px"
            }}>
                Oops!
            </h1>
            <p style={{
                fontSize: "20px",
                lineHeight: "1.5",
                // color: "#666666",
                marginBottom: "20px"
            }}>
                Sorry, an unexpected error has occurred.
            </p>
            <p style={{
                fontSize: "18px",
                fontStyle: "italic",
                // color: "#666666"
            }}>
                {error?.statusText || error?.message}
            </p>
        </div>
    );
};

export default ErrorPage;
