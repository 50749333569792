import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Client_ from '../../api/Client';
import { useNotification } from '../../stores/actions/StoreActions';
import Link from '../Link';

interface SpotifyProps { }


const exampleTracklist = `0:09 ID - ID
1:18 Chocolate Puma ft. Kris Kiss - Frenzy
4:34 ID - ID
7:32 Dombresky - Bubblin
9:48  Tchami - Born Again
12:25 DLMT & Paraleven - One Drink
12:30 Belcastro - Pressure
13:51 Öwnboss & Sevek - Move Your Body
14:39 John Summit - La Danza
17:53 DJ Snake & Tchami & Malaa & MERCER - Made In France
18:50 Drake ft. Future & Young Thug - Way 2 Sexy (Valentino Khan Remix)
20:53 Tchami & Frents - ID
23:53 Tchami & AC Slater ft. Kate Wild - Only One
26:30 Tchami - Siaw
28:00 ID - ID
30:15 DMNDS & Fallen Roses & Lujavo ft. Lunis - Calabria (Shift K3Y Edit)
33:30 Tchami ft. Gunna - Praise
36:50 Dog Blood vs. Rob Base & DJ E-Z Rock vs. Tchami & Marshall Jefferson - Turn Off The Light vs. It Takes Two vs. Move Your Body (Tchami Mashup)
38:27 DJ Snake & AlunaGeorge - You Know You Like It (Tchami Remix)
40:15 MARTEN HØRGER & BRANDON - Let's Try It
43:00 Tchami ft. Stacy Barthe - After Life (Habstrakt Remix)
43:31 Michael Sparks - Tell Me
46:30 Curbi & Mike Cervello - Deja Vu
47:28 Wax Motif & longstoryshort - On The Low
49:44 Flume & MAY-A - Say Nothing (Tchami
Remix)
52:00 Sonny Fodera & Biscits - Perfect
55:29 Tchami - Adieu vs. Adieu Pt. II (Tchami Outro Mashup)`

const examplePlaylistName = 'Tchami - Ultra Music Festival Miami 2022'

const SetPlaylist: React.FC<SpotifyProps> = () => {
    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const { addNotificationError, addNotificationSuccess, addNotification } = useNotification()

    const [playlistName, setPlaylistName] = useState("My Set Tracklist");
    const [tracklist, setTracklist] = useState("");
    const [generatedPlaylistUrl, setGeneratedPlaylistUrl] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [needsSpotifyLogin, setNeedsSpotifyLogin] = useState(false);
    const [couldntFindSongs, setCouldntFindSongs] = useState<string[]>([]);

    useEffect(() => {
        document.title = 'Create Set Playlist';
        // Define a function to handle the Spotify authentication check
        const checkSpotifyAuth = async () => {
            const { err, res } = await Client_.client_().validateSpotifyAuth();
            if (err || !res?.success) {
                addNotificationError(err);
            } else {
                if (res?.result?.success) {
                    setNeedsSpotifyLogin(false);
                } else {
                    addNotificationError('Please login to Spotify first');
                    setNeedsSpotifyLogin(true);
                    navigate('/spotify-auth');
                }
            }
        };
        checkSpotifyAuth();
        const intervalId = setInterval(checkSpotifyAuth, 30000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);


    const createSetPlaylist = (tracklist: string, playlistName: string) => {
        setIsLoading(true);
        setGeneratedPlaylistUrl('');
        Client_.client_().createSetPlaylist(tracklist, playlistName).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                setGeneratedPlaylistUrl(res?.result?.playlist_url);
                setCouldntFindSongs(res?.result?.couldnt_find_tracks);
            }
        });
    }


    const setExampleData = () => {
        setTracklist(exampleTracklist);
        setPlaylistName(examplePlaylistName);
        createSetPlaylist(exampleTracklist, examplePlaylistName);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <h1 style={{ textAlign: 'center' }}>Create a Set Playlist</h1>
            {needsSpotifyLogin &&
                <p style={{ color: 'red', fontWeight: 'bold', fontSize: 20 }}>Please login to Spotify first, click <Link to='/spotify-auth' target='_blank' rel='noreferrer'>here</Link> if not redirected automatically.</p>
            }
            <p>Paste the tracklist of the set you want to create a Spotify playlist for</p>

            <TextField
                sx={{ width: '75%', mb: 2 }}
                id="outlined-basic"
                label="Playlist Name"
                variant="outlined"
                value={playlistName}
                onChange={(event) => setPlaylistName(event.target.value)}
            />
            <TextField
                sx={{ width: '75%', mb: 2 }}
                id="outlined-basic"
                label="Tracklist"
                variant="outlined"
                value={tracklist}
                multiline
                rows={10}
                onChange={(event) => setTracklist(event.target.value)}
            />

            <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                {isLoading ?
                    <CircularProgress />
                    :
                    <>
                        <Button variant="contained" color="primary" size="medium" onClick={() => createSetPlaylist(tracklist, playlistName)}>Create Playlist</Button>
                        <Button variant='contained' color='secondary' onClick={() => setExampleData()} sx={{ ml: 2 }}>Use Example</Button>
                    </>
                }
            </div>

            {generatedPlaylistUrl &&
                <>
                    <hr style={{ width: '75%', marginTop: 20 }} />
                    <h2 style={{ marginTop: 10 }}>Here's the playlist for the set:</h2>
                    <Link to={generatedPlaylistUrl} target="_blank" rel="noreferrer">{generatedPlaylistUrl}</Link>
                    <p>Note: the playlist may not be 100% accurate</p>
                </>
            }
            {couldntFindSongs?.length > 0 &&
                <>
                    <h2 style={{ marginTop: 10 }}>Couldn't find a song on Spotify for the following tracks:</h2>
                    {couldntFindSongs.map((tracks: string) => {
                        return <p>{tracks}</p>
                    })}
                </>
            }
        </div>
    );
};

export default SetPlaylist;
