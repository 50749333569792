const utils = {
    getTwitterUserFromUrl: (url) => {
        const regex = /twitter\.com\/([a-zA-Z0-9_]+)/;
        const match = regex.exec(url);
        if (match) {
            return match[1];
        }
        return url;
    },
    getHumanReadableDate: (dateString) => {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
        return date.toLocaleString('en-US', options);
    },
    getTimeElapsed: (dateString) => {
        const tweetDate = new Date(dateString);
        const now = new Date();
        const diff = now.getTime() - tweetDate.getTime();

        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);
        const years = Math.floor(months / 12);

        if (years > 0) {
            return `${years}y ago`;
        } else if (months > 0) {
            return `${months}mo ago`;
        } else if (days > 0) {
            return `${days}d ago`;
        } else if (hours > 0) {
            return `${hours}h ago`;
        } else if (minutes > 0) {
            return `${minutes}m ago`;
        } else {
            return `${seconds}s ago`;
        }
    },
    shortNumberFormat: (num) => {
        if (num < 1000) {
            return num
        }
        const units = ['', 'K', 'M', 'B', 'T'];
        let decimalPlaces = 1;
        let unitIndex = 0;
        while (num >= 1000 && unitIndex < units.length - 1) {
            num /= 1000;
            unitIndex++;
        }
        if (num < 10) {
            decimalPlaces = 2;
        } else if (num < 100) {
            decimalPlaces = 1;
        }
        return num.toFixed(decimalPlaces) + units[unitIndex];
    },
}

export default utils;