import React, { useEffect, useRef } from 'react';

declare global {
    interface Window {
        adsbygoogle: any[];
    }
}

const AdUnit = () => {
    const adRef = useRef<HTMLModElement>(null);
    const adInitialized = useRef(false);

    useEffect(() => {
        if (adRef.current && !adInitialized.current) {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
                adInitialized.current = true;
            } catch (e) {
                console.error('AdSense error:', e);
            }
        }
    }, []);

    return (
        <ins
            className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-4573183079906918"
            data-ad-slot="1965924680"
            data-ad-format="auto"
            data-full-width-responsive="true"
            ref={adRef}
        />
    );
};

export default AdUnit;