import FileCopyIcon from '@mui/icons-material/FileCopy';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Client_ from '../../api/Client';
import { useNotification } from '../../stores/actions/StoreActions';
import Divider from '@mui/material/Divider';
import AdUnit from '../AdUnit/AdUnit';
import StarIcon from '@mui/icons-material/Star';
import { Box, Container, Typography } from '@mui/material';
import Switch from '@mui/material/Switch';
import ReactGA from 'react-ga4';

interface CartineseProps { }

const MAX_CARTI_INPUT_LENGTH = 100;

const Cartinese: React.FC<CartineseProps> = () => {
    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const { addNotificationError, addNotificationSuccess, addNotification } = useNotification()
    const dispatch = useDispatch();

    const [input, setInput] = useState("");
    const [cartinese, setCartinese] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    useEffect(() => {
        document.title = 'Playboi Carti Translator';
        setOpenSnackbar(true);
    }, []);

    const cartify = () => {
        setIsLoading(true);
        setCartinese("");
        Client_.client_().cartify(input).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                setCartinese(res?.result?.cartinese);
            }
        });
    };

    const handleCopyText = () => {
        navigator.clipboard.writeText(cartinese);
        addNotificationSuccess("Copied to clipboard");
    };

    const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const handlePremiumClick = () => {
        ReactGA.event('view_item', {
            items: [{
                id: 'cartinese_premium',
                name: 'Cartinese Premium',
                category: 'Premium',
                quantity: 1,
                price: 1.00
            }]
        });
        navigate('/cartinese/purchase');
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newInput = event.target.value.slice(0, MAX_CARTI_INPUT_LENGTH);
        setInput(newInput);
    };

    return (
        <Container maxWidth="sm">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 3 }}>
                <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
                    Playboi Carti Translator
                </Typography>
                <Box sx={{ width: '100%', position: 'relative' }}>
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        variant="outlined"
                        value={input}
                        multiline
                        rows={4}
                        onChange={handleInputChange}
                        placeholder={`** !++ Enter the++**💕 text* ! +:)💔 you. .$ wantxo !😦 translated++ ++ 🖤#love *`}
                        sx={{ mb: 1 }}
                        inputProps={{
                            maxLength: MAX_CARTI_INPUT_LENGTH
                        }}
                        helperText={
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <Typography
                                    variant="caption"
                                    sx={{
                                        color: input.length >= MAX_CARTI_INPUT_LENGTH * 0.8 ? 'error.main' : 'text.secondary'
                                    }}
                                >
                                    {MAX_CARTI_INPUT_LENGTH - input.length} characters🦋 remaining🖤 * ok  !+
                                </Typography>
                            </Box>
                        }
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            mb: 2,
                            backgroundColor: 'background.paper',
                            borderRadius: '12px',
                            padding: 2,
                            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                        }}
                    >
                        <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
                            Translation Options
                        </Typography>
                        {[
                            { label: 'Vamp Carti', name: 'vamp' },
                            { label: 'Instant Translation', name: 'instant' },
                            { label: 'Unlimited Characters', name: 'unlimited' },
                        ].map((option) => (
                            <Box
                                key={option.name}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    mb: 1,
                                }}
                            >
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    {option.label}
                                </Typography>
                                <Switch
                                    size="small"
                                    checked={false}
                                    onChange={handlePremiumClick}
                                    name={option.name}
                                    color="primary"
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>

                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {isLoading ? (
                        <CircularProgress sx={{ mb: 2 }} />
                    ) : (
                        <Box sx={{ width: '100%', mb: 2 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2, width: '100%' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => cartify()}
                                    fullWidth
                                    sx={{
                                        borderRadius: '28px',
                                        textTransform: 'none',
                                        py: 1.5,
                                        fontSize: '1rem',
                                        fontWeight: 500,
                                        boxShadow: '0 3px 5px rgba(0,0,0,0.2)',
                                    }}
                                >
                                    Translate
                                </Button>
                            </Box>
                            {/* <Button
                                fullWidth
                                variant="outlined"
                                startIcon={<StarIcon />}
                                onClick={handlePremiumClick}
                                sx={{
                                    borderRadius: '28px',
                                    textTransform: 'none',
                                    py: 1,
                                    fontSize: '0.9rem',
                                    color: 'secondary.main',
                                    borderColor: 'secondary.main',
                                    '&:hover': {
                                        backgroundColor: 'secondary.light',
                                    },
                                }}
                            >
                                Skip the Wait - Upgrade for \$1
                            </Button> */}
                        </Box>
                    )}
                </Box>
                <Divider sx={{ width: '100%', mb: 2 }} />

                {cartinese && (
                    <Box sx={{ width: '100%', mb: 2 }}>
                        <TextField
                            fullWidth
                            multiline
                            minRows={3}
                            label="Cartinese"
                            placeholder="Empty"
                            value={cartinese}
                            onChange={(event) => setCartinese(event.target.value)}
                            InputProps={{
                                readOnly: true,
                                style: { color: 'white' }
                            }}
                            sx={{
                                mb: 2,
                                backgroundColor: 'inherit',
                                '& .MuiInputBase-root': {
                                    height: 'auto',
                                    overflow: 'auto'
                                }
                            }}
                        />

                        <Button
                            fullWidth
                            variant="contained"
                            startIcon={<FileCopyIcon />}
                            onClick={handleCopyText}
                        >
                            Copy
                        </Button>
                    </Box>
                )}
                <Box sx={{ width: '100%', mb: 2 }}>
                    <AdUnit />
                </Box>
            </Box>
        </Container>
    );
};

export default Cartinese;