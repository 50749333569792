import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNotification } from '../../stores/actions/StoreActions';
import Client_ from '../../api/Client';
import { Document, Page, pdfjs } from 'react-pdf';
import Editor, { loader } from '@monaco-editor/react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import ListItemIcon from '@mui/material/ListItemIcon';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// Material-UI imports
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Constants from '../../constants/Constants';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import FormGroup from '@mui/material/FormGroup';
import debounce from 'lodash/debounce';
import DownloadIcon from '@mui/icons-material/Download';

// Material-UI icons
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const ResumeContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 64px)', // Adjust this value based on your app's header height
    overflow: 'hidden',
});

const ContentContainer = styled(Box)({
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
});

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

const EditorWrapper = styled(Paper)(({ theme }) => ({
    width: '50%',
    height: '100%',
    overflow: 'hidden', // Changed from 'auto' to 'hidden'
}));

const PDFRenderer = styled(Box)(({ theme }) => ({
    // padding: theme.spacing(2),
    width: '50%',
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
}));

const Resume = () => {
    const [latexContent, setLatexContent] = useState(Constants.baseLatexResume);
    const [debouncedLatexContent, setDebouncedLatexContent] = useState(Constants.baseLatexResume);
    const [selectedText, setSelectedText] = useState('');
    const [selectionPosition, setSelectionPosition] = useState<{ top: number; left: number } | null>(null);
    const [showWand, setShowWand] = useState(false);
    const [wandLoading, setWandLoading] = useState(false);
    const [userPrompt, setUserPrompt] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [pdfWidth, setPdfWidth] = useState(0);
    const editorRef = useRef(null);
    const pdfContainerRef = useRef<HTMLDivElement>(null);
    const [isDarkMode, setIsDarkMode] = useState(true);
    const [autoReload, setAutoReload] = useState(true);
    const [improvedSnippets, setImprovedSnippets] = useState<string[]>([]);
    const [numPages, setNumPages] = useState<number | null>(null);
    const [advancedRewrite, setAdvancedRewrite] = useState(false);

    const dispatch = useDispatch();
    const { addNotificationError, addNotificationSuccess } = useNotification();

    useEffect(() => {
        document.title = 'Resume Editor';
    }, []);

    useEffect(() => {
        return () => {
            if (pdfUrl) {
                URL.revokeObjectURL(pdfUrl);
            }
        };
    }, [pdfUrl]);

    useEffect(() => {
        const updatePdfWidth = () => {
            if (pdfContainerRef.current) {
                setPdfWidth(pdfContainerRef.current.offsetWidth - 32); // Subtract padding
            }
        };

        updatePdfWidth();
        window.addEventListener('resize', updatePdfWidth);

        return () => {
            window.removeEventListener('resize', updatePdfWidth);
        };
    }, []);

    useEffect(() => {
        loader.init().then((monaco) => {
            monaco.editor.defineTheme('latex-dark', {
                base: 'vs-dark',
                inherit: true,
                rules: [
                    { token: 'keyword', foreground: '569CD6' },
                    { token: 'comment', foreground: '608B4E' },
                ],
                colors: {},
            });

            monaco.editor.defineTheme('latex-light', {
                base: 'vs',
                inherit: true,
                rules: [
                    { token: 'keyword', foreground: '0000FF' },
                    { token: 'comment', foreground: '008000' },
                ],
                colors: {},
            });

            // Register LaTeX language
            monaco.languages.register({ id: 'latex' });
            monaco.languages.setMonarchTokensProvider('latex', {
                tokenizer: {
                    root: [
                        [/\\[a-zA-Z]+/, 'keyword'],
                        [/%.*$/, 'comment'],
                    ],
                },
            });
        });
    }, []);

    // Create a debounced function to update the LaTeX content
    const debouncedSetLatexContent = useCallback(
        debounce((value: string) => {
            setDebouncedLatexContent(value);
        }, 750),
        []
    );

    useEffect(() => {
        if (autoReload) {
            generatePDF();
        }
    }, [debouncedLatexContent, autoReload]);

    const generatePDF = async () => {
        setIsLoading(true);
        try {
            const { err, res } = await Client_.client_().convertLatexToPdf(debouncedLatexContent);
            setIsLoading(false);

            if (err) {
                addNotificationError(err);
                return;
            }

            if (res instanceof Blob) {
                if (res.size > 0) {
                    const pdfUrl = URL.createObjectURL(res);
                    setPdfUrl(pdfUrl);
                } else {
                    console.error("PDF Blob is empty");
                    addNotificationError("Generated PDF is empty. Please check your LaTeX content.");
                }
            } else {
                console.error("Unexpected response type:", typeof res);
                addNotificationError("Unexpected response from server. Please try again.");
            }
        } catch (error) {
            setIsLoading(false);
            console.error("Error in PDF generation process:", error);
            addNotificationError("An error occurred while generating the PDF. Please try again.");
        }
    };

    const handleEditorChange = (value: string | undefined) => {
        if (value !== undefined) {
            setLatexContent(value);
            debouncedSetLatexContent(value);
        }
    };

    const handleEditorDidMount = (editor: any, monaco: any) => {
        editorRef.current = editor;
        monaco.editor.setTheme(isDarkMode ? 'latex-dark' : 'latex-light');

        editor.onDidChangeCursorSelection((e: any) => {
            const selection = editor.getSelection();
            const selectedText = editor.getModel().getValueInRange(selection);

            if (selectedText) {
                setSelectedText(selectedText);
                const { endLineNumber, endColumn } = selection;
                const position = editor.getScrolledVisiblePosition({ lineNumber: endLineNumber, column: endColumn });
                const editorContainer = editor.getContainerDomNode();
                const editorBounds = editorContainer.getBoundingClientRect();

                // Calculate the position relative to the entire page
                setSelectionPosition({
                    top: position.top + editorBounds.top + window.scrollY,
                    left: position.left + editorBounds.left + window.scrollX
                });
                setShowWand(true);
            } else {
                setShowWand(false);
            }
        });
    };

    const handleAdvancedRewriteToggle = () => {
        setAdvancedRewrite(!advancedRewrite);
    };

    const handleWandClick = () => {
        if (advancedRewrite) {
            setOpenDialog(true);
        } else {
            handleRewrite();
        }
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setUserPrompt('');
        setImprovedSnippets([]);
    };

    const handleRewrite = () => {
        setWandLoading(true);
        Client_.client_().rewriteResumeSection(selectedText, advancedRewrite ? userPrompt : '').then(({ err, res }) => {
            setWandLoading(false);
            if (err || !res?.success) {
                addNotificationError(err || 'Failed to rewrite section');
            } else {
                setImprovedSnippets(res?.result?.improved_snippets || []);
                if (!advancedRewrite) {
                    setOpenDialog(true);
                }
            }
        });
    };

    const handleSnippetSelect = (snippet: string) => {
        // Replace the selected text with the chosen snippet
        const updatedContent = latexContent.replace(selectedText, snippet);
        setLatexContent(updatedContent);
        debouncedSetLatexContent(updatedContent);

        // Clear the improved snippets and selected text
        setImprovedSnippets([]);
        setSelectedText('');

        // Close the dialog
        handleDialogClose();
    };

    const handlePromptSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleRewrite();
        }
    };

    const handleThemeToggle = () => {
        setIsDarkMode(!isDarkMode);
        if (editorRef.current) {
            const editor = editorRef.current as any;
            editor.updateOptions({ theme: isDarkMode ? 'latex-light' : 'latex-dark' });
        }
    };

    const handleAutoReloadToggle = () => {
        setAutoReload(!autoReload);
    };

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
    };

    const handleDownloadPDF = () => {
        if (pdfUrl) {
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.download = 'resume.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            addNotificationError('PDF not available for download. Please generate it first.');
        }
    };

    return (
        <ResumeContainer>
            <StyledToolbar>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '50%' }}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        LaTeX
                    </Typography>
                    <FormControlLabel
                        control={<Switch checked={isDarkMode} onChange={handleThemeToggle} />}
                        label='Dark Mode'
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '50%' }}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, m: 1 }}>
                        Preview
                    </Typography>
                    <FormGroup row>
                        {false && (
                            <FormControlLabel
                                control={<Switch checked={advancedRewrite} onChange={handleAdvancedRewriteToggle} />}
                                label="Custom Instructions"
                            />
                        )}
                        <FormControlLabel
                            control={<Switch checked={autoReload} onChange={handleAutoReloadToggle} />}
                            label="Auto Compile"
                        />
                        <Tooltip title="Download PDF">
                            <IconButton
                                onClick={handleDownloadPDF}
                                disabled={!pdfUrl}
                                color="primary"
                            >
                                <DownloadIcon />
                            </IconButton>
                        </Tooltip>
                    </FormGroup>
                </Box>
            </StyledToolbar>
            <ContentContainer>
                <EditorWrapper>
                    <Editor
                        height="100%"
                        defaultLanguage="latex"
                        value={latexContent}
                        onChange={handleEditorChange}
                        onMount={handleEditorDidMount}
                        theme={isDarkMode ? 'latex-dark' : 'latex-light'}
                        options={{
                            minimap: { enabled: false },
                            wordWrap: 'on',
                            wrappingIndent: 'indent',
                            lineNumbers: 'on',
                            renderLineHighlight: 'all',
                            scrollBeyondLastLine: false,
                        }}
                    />
                    {showWand && selectionPosition && (
                        <Tooltip title="Rewrite selected text">
                            <IconButton
                                sx={{
                                    position: 'absolute',
                                    top: `${selectionPosition.top}px`,
                                    left: `${selectionPosition.left}px`,
                                    transform: 'translate(0, -50%)',
                                    zIndex: 1000,
                                    pointerEvents: wandLoading ? 'none' : 'auto',
                                }}
                                onClick={handleWandClick}
                                disabled={wandLoading}
                            >
                                {wandLoading ? <CircularProgress size={24} /> : <AutoFixHighIcon />}
                            </IconButton>
                        </Tooltip>
                    )}
                </EditorWrapper>

                <PDFRenderer ref={pdfContainerRef}>
                    {pdfUrl && (
                        <Document
                            file={pdfUrl}
                            onLoadSuccess={onDocumentLoadSuccess}
                            onLoadError={(error) => {
                                console.error('PDF load error:', error);
                                // addNotificationError('Failed to load PDF');
                            }}
                        >
                            {Array.from(new Array(numPages), (el, index) => (
                                <Page
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                    width={pdfWidth}
                                    renderTextLayer={true}
                                    renderAnnotationLayer={true}
                                />
                            ))}
                        </Document>
                    )}
                </PDFRenderer>
            </ContentContainer>
            <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="md" fullWidth>
                {/* <DialogTitle sx={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold' }}>
                    Enhance Your Resume
                </DialogTitle> */}
                <DialogContent>
                    {advancedRewrite && improvedSnippets.length === 0 ? (
                        <Box sx={{ padding: '20px', textAlign: 'center' }}>
                            <Typography variant="h6" gutterBottom>
                                Describe how you would like to improve this section.
                            </Typography>
                            <TextField
                                autoFocus
                                margin='dense'
                                label='Enter your prompt here'
                                type='text'
                                fullWidth
                                variant='outlined'
                                value={userPrompt}
                                onChange={(e) => setUserPrompt(e.target.value)}
                                onKeyPress={handlePromptSubmit}
                                multiline
                                rows={4}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'primary.main',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'primary.dark',
                                        },
                                    },
                                }}
                            />
                        </Box>
                    ) : (
                        <>
                        <Typography variant="h6" gutterBottom sx={{ textAlign: 'center' }}>
                            Select an improved snippet
                        </Typography>
                        <List sx={{ maxHeight: '400px', overflow: 'auto' }}>
                            {improvedSnippets.map((snippet, index) => (
                                <ListItem
                                    key={index}
                                    button
                                    onClick={() => handleSnippetSelect(JSON.stringify(snippet).slice(1, -1).replace(/\\\\/g, '\\'))}
                                    sx={{
                                        border: '1px solid #e0e0e0',
                                        borderRadius: '8px',
                                        marginBottom: '12px',
                                        transition: 'all 0.3s',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                            transform: 'translateY(-2px)',
                                            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                                        },
                                    }}
                                >
                                    <ListItemText
                                        primary={JSON.stringify(snippet).slice(1, -1).replace(/\\\\/g, '\\')}
                                        primaryTypographyProps={{
                                            style: { fontWeight: 'bold', whiteSpace: 'pre-wrap' }
                                        }}
                                    />
                                </ListItem>
                            ))}
                        </List>
                        </>
                    )}
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', padding: '16px' }}>
                    <Button onClick={handleDialogClose} variant="outlined" color="secondary">
                        Cancel
                    </Button>
                    {advancedRewrite && improvedSnippets.length === 0 && (
                        <Button
                            onClick={handleRewrite}
                            disabled={isLoading}
                            variant="contained"
                            color="primary"
                            sx={{ minWidth: '120px' }}
                        >
                            {isLoading ? <CircularProgress size={24} /> : 'Rewrite'}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </ResumeContainer>
    );
};

export default Resume;