import { default as DeleteIcon } from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import 'chart.js/auto';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Client_ from '../../api/Client';
import constants from '../../constants/Constants';
import { useNotification, useUser } from '../../stores/actions/StoreActions';
import Link from '../Link';

interface SpotifyProps { }

const GREEN = '#00cc66';
const RED = '#990000';


const SpotifyDuplicates: React.FC<SpotifyProps> = () => {
    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const { addNotificationError, addNotificationSuccess, addNotification } = useNotification()
    const dispatch = useDispatch();

    const { forgotPassword } = useUser();

    const [playlistUrl, setPlaylistUrl] = useState("");
    const [duplicateTracks, setDuplicateTracks] = useState([]);
    const [playlistId, setPlaylistId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [needsSpotifyLogin, setNeedsSpotifyLogin] = useState(false);

    useEffect(() => {
        document.title = 'Remove Duplicates';
        const checkSpotifyAuth = async () => {
            const { err, res } = await Client_.client_().validateSpotifyAuth();
            if (err || !res?.success) {
                addNotificationError(err);
            } else {
                if (res?.result?.success) {
                    setNeedsSpotifyLogin(false);
                } else {
                    addNotificationError('Please login to Spotify first');
                    setNeedsSpotifyLogin(true);
                    navigate('/spotify-auth');
                }
            }
        };
        checkSpotifyAuth();
        const intervalId = setInterval(checkSpotifyAuth, 30000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const findDuplicates = (playlistUrl: string) => {
        setIsLoading(true);
        setDuplicateTracks([]);
        Client_.client_().findDuplicates(playlistUrl).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                setDuplicateTracks(res?.result?.tracks);
                setPlaylistId(res?.result?.playlist_id);
            }
        });
    };

    const removeDuplicates = (playlistId: string, duplicates: any) => {
        setIsLoading(true);
        Client_.client_().removeDuplicates(playlistId, duplicates).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                addNotificationSuccess('Duplicates removed successfully');
                setDuplicateTracks([]);
            }
        });
    }

    const handleDeleteRow = (itemId: any) => {
        setDuplicateTracks((prevItems: any) => prevItems.filter((prevItem: any) => prevItem.id !== itemId));
    };

    const setExampleData = () => {
        setPlaylistUrl(constants.exampleDuplicateSpotifyUrl);
        findDuplicates(constants.exampleDuplicateSpotifyUrl);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <h1 style={{ textAlign: 'center' }}>Remove Duplicate Songs from your Playlist</h1>
            {needsSpotifyLogin &&
                <p style={{ color: RED, fontWeight: 'bold', fontSize: 20 }}>Please login to Spotify first, click <Link to='/spotify-auth' target='_blank' rel='noreferrer'>here</Link> if not redirected automatically.</p>
            }
            <p>Enter the URL of the Spotify playlist you want to find and remove duplicate entries for</p>
            <p>Stuck? Right click your playlist {'>'} Share {'>'} Copy link to playlist</p>
            <TextField
                sx={{ width: '75%', mb: 2 }}
                id="outlined-basic"
                label="Playlist URL"
                variant="outlined"
                value={playlistUrl}
                onChange={(event) => setPlaylistUrl(event.target.value)}
            />
            <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                {isLoading ?
                    <CircularProgress />
                    :
                    <>
                        <Button variant="contained" color="primary" size="medium" onClick={() => findDuplicates(playlistUrl)}>Find Duplicates</Button>
                        <Button variant='contained' color='secondary' onClick={() => setExampleData()} sx={{ ml: 2 }}>Use Example</Button>
                    </>
                }
            </div>

            {duplicateTracks?.length > 0 &&
                <>
                <hr style={{ width: '75%', marginTop: 20 }} />
                <TableContainer component={Paper} sx={{ m: 2 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Track</TableCell>
                                <TableCell>Album</TableCell>
                                <TableCell>Position</TableCell>
                                <TableCell>Replacement Track</TableCell>
                                <TableCell>Replacement Album</TableCell>
                                <TableCell>Replacement Position</TableCell>
                                <TableCell>Replacement Reason</TableCell>
                                <TableCell>Exclude From Deletion</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {duplicateTracks?.map((item: any) => (
                                <TableRow key={item?.id}>
                                    {/* make the background green and the other red */}
                                    <TableCell sx={{ backgroundColor: RED }}>{item?.track?.name}</TableCell>
                                    <TableCell sx={{ backgroundColor: RED }}>{item?.track?.album?.name}</TableCell>
                                    <TableCell sx={{ backgroundColor: RED }}>{item?.position}</TableCell>
                                    <TableCell sx={{ backgroundColor: GREEN }}>{item?.replacement?.track?.name}</TableCell>
                                    <TableCell sx={{ backgroundColor: GREEN }}>{item?.replacement?.track?.album?.name}</TableCell>
                                    <TableCell sx={{ backgroundColor: GREEN }}>{item?.replacement?.position}</TableCell>
                                    <TableCell sx={{ backgroundColor: GREEN }}>{item?.reason}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleDeleteRow(item?.id)}>
                                            <DeleteIcon sx={{ color: '#FF695E' }} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    {isLoading ?
                        <CircularProgress />
                        :
                        <>
                            <Button variant="contained" color="primary" size="medium" onClick={() => removeDuplicates(playlistId, duplicateTracks)}>Remove Duplicates</Button>
                        </>
                    }
                </div>
                </>
            }
        </div>
    );
};

export default SpotifyDuplicates;
