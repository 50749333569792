import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Box, Paper, CircularProgress } from '@mui/material';
import Client_ from '../../api/Client';
import { useNotification } from '../../stores/actions/StoreActions';
import { useLocation } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Chip from '@mui/material/Chip';
import ReactGA from 'react-ga4';

interface CartinesePurchaseProps { }

const PREMIUM_FEATURES = [
    'Instant translations',
    'Vamp carti and other eras of cartinese',
    'Unlimited translations of any length',
    'Customizable translation parameters',
    'Ad-free experience',
    'Priority new features',
];

const ORIGINAL_PRICE = 4.99;
const MONTHLY_PRICE = 1.99;
const DISCOUNT_PERCENTAGE = 80;
const COUPON_CODE = 'EARLYBIH';

const CartinesePurchase: React.FC<CartinesePurchaseProps> = () => {
    // State variables for prices and discount
    const [originalPrice] = useState(ORIGINAL_PRICE);
    const [monthlyPrice] = useState(MONTHLY_PRICE);
    const [discountPercentage] = useState(DISCOUNT_PERCENTAGE);
    const [couponCode] = useState(COUPON_CODE);
    const [discountedPrice] = useState(originalPrice * (1 - discountPercentage / 100));

    // State for promo code and loading
    const [promoCode, setPromoCode] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [purchaseStatus, setPurchaseStatus] = useState<'initial' | 'success' | 'failure'>('initial');

    const { addNotificationError, addNotificationSuccess } = useNotification();
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const success = searchParams.get('success');

        if (success === 'true') {
            ReactGA.event('purchase', {
                currency: 'USD',
                value: discountedPrice,
                items: [{
                    id: 'cartinese_premium',
                    name: 'Cartinese Premium',
                    category: 'Premium',
                    quantity: 1,
                    price: discountedPrice
                }]
            });
            setPurchaseStatus('success');
            addNotificationSuccess('Thank you for your purchase! We will be in touch soon.');
        } else if (success === 'false') {
            setPurchaseStatus('failure');
        }
    }, [location, addNotificationSuccess]);

    // Function to handle purchase
    const handlePurchase = () => {
        ReactGA.event('begin_checkout', {
            currency: 'USD',
            value: discountedPrice,
            items: [{
                id: 'cartinese_premium',
                name: 'Cartinese Premium',
                category: 'Premium',
                quantity: 1,
                price: discountedPrice
            }]
        });
        setIsLoading(true);
        Client_.client_().createCartineseCheckoutSession().then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err || 'Failed to create checkout session');
                ReactGA.event('exception', {
                    description: 'Failed to create checkout session' + (err ? ': ' + err : ''),
                    fatal: true
                });
                return;
            }
            // Redirect to Stripe Checkout

            window.location.href = res.result.url;
        });
    };

    if (purchaseStatus === 'success') {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3, maxWidth: 600, margin: 'auto' }}>
                <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold' }}>
                    Purchase Successful!
                </Typography>
                <Typography variant="body1" sx={{ textAlign: 'center', mt: 2 }}>
                    Thank you for purchasing Cartinese Premium. We will be in touch soon with further details about your account activation.
                </Typography>
            </Box>
        );
    }

    if (purchaseStatus === 'failure') {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3, maxWidth: 600, margin: 'auto' }}>
                <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold', color: 'error.main' }}>
                    Purchase Not Completed
                </Typography>
                <Typography variant="body1" sx={{ textAlign: 'center', mt: 2 }}>
                    We're sorry, but your purchase was not completed. Please try again or contact support if you continue to experience issues.
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePurchase}
                    sx={{ mt: 4 }}
                >
                    Try Again
                </Button>
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: { xs: 2, md: 3 }, maxWidth: '100%', margin: 'auto' }}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center', mb: 2, color: 'primary.main' }}>
                🚀 Unlock Cartinese Premium
            </Typography>

            <Paper elevation={6} sx={{ p: { xs: 3, md: 4 }, width: '100%', maxWidth: 450, borderRadius: 3, boxShadow: '0 5px 15px rgba(0,0,0,0.1)' }}>
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center', mb: 2, color: 'secondary.main' }}>
                    Early Access Offer
                </Typography>

                <Box sx={{ mb: 3 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'medium', mb: 1 }}>Premium Features*</Typography>
                    {PREMIUM_FEATURES.map((feature, index) => (
                        <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <CheckCircleIcon sx={{ color: '#1DB954', mr: 1, fontSize: '1rem' }} />
                            <Typography variant="body2">{feature}</Typography>
                        </Box>
                    ))}
                </Box>

                <Box sx={{ textAlign: 'center', mb: 3 }}>
                    <Typography variant="body2" sx={{ textDecoration: 'line-through', color: 'text.secondary' }}>
                        Regular: ${originalPrice}
                    </Typography>
                    <Typography variant="h6" color="error" sx={{ fontWeight: 'bold', my: 1 }}>
                        {discountPercentage}% OFF
                    </Typography>
                    <Typography variant="h5" color="primary" sx={{ fontWeight: 'bold', mb: 1 }}>
                        Your Price: ${Number.isInteger(discountedPrice) ? discountedPrice : discountedPrice.toFixed(2)}
                    </Typography>
                    <Chip label={`Use code: "${couponCode}"`} color="secondary" size="medium" />
                </Box>

                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handlePurchase}
                    disabled={isLoading}
                    sx={{
                        py: 1.5,
                        fontSize: '1.1rem',
                        fontWeight: 'bold',
                        borderRadius: 2,
                        mb: 2,
                        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
                        '&:hover': {
                            background: 'linear-gradient(45deg, #FE8B8B 30%, #FFA853 90%)',
                        }
                    }}
                >
                    {isLoading ? <CircularProgress size={24} color="inherit" /> : '🔥 Get Lifetime Access 🔥'}
                </Button>

                <Typography variant="caption" color="text.secondary" sx={{ display: 'block', textAlign: 'center', mb: 1 }}>
                    30-Day Money-Back Guarantee • Secure Payment
                </Typography>
                <Typography variant="caption" color="text.secondary" sx={{ display: 'block', textAlign: 'center' }}>
                    *Premium features coming soon
                </Typography>
            </Paper>
        </Box>
    );
};

export default CartinesePurchase;