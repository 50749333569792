import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MobileStepper from '@mui/material/MobileStepper';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import LazyLoad from 'react-lazyload';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Link from '../Link';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

interface SwipeableTextMobileStepperProps {
    tweetImages: string[];
}


const SwipeableTextMobileStepper: React.FC<SwipeableTextMobileStepperProps> = ({ tweetImages }) => {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = tweetImages.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    return (
        <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {tweetImages.map((step, index) => (
                    <div key={step}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <LazyLoad height={400} once>
                                {step.includes('.mp4') ? (
                                    <Box
                                        component="video"
                                        sx={{
                                            display: 'block',
                                            maxWidth: 400,
                                            overflow: 'hidden',
                                            width: '100%',
                                        }}
                                        src={step}
                                        controls // add this prop to display video controls
                                        autoPlay // add this prop to automatically play the video
                                        muted // add this prop to mute the video (optional)
                                        loop // add this prop to enable continuous loop (optional)
                                    />) : (
                                    <Link to={step} target="_blank">
                                        <Box
                                            component="img"
                                            sx={{
                                                display: 'block',
                                                maxWidth: 400,
                                                overflow: 'hidden',
                                                width: '100%',
                                            }}
                                            src={step}
                                        />
                                    </Link>
                                )}
                            </LazyLoad>
                        ) : null}
                    </div>
                ))}
            </SwipeableViews>
            {tweetImages.length > 1 &&
                <MobileStepper
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <Button
                            size="small"
                            onClick={handleNext}
                            disabled={activeStep === maxSteps - 1}
                        >
                            Next
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                            ) : (
                                <KeyboardArrowRight />
                            )}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                            )}
                            Back
                        </Button>
                    }
                />
            }
        </Box>
    );
}

export default SwipeableTextMobileStepper;