import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Fab from '@mui/material/Fab';
import TextField from '@mui/material/TextField';
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Client_ from '../../api/Client';
import Constants from '../../constants/Constants';
import { useNotification, useUser } from '../../stores/actions/StoreActions';

interface PasswordResetProps { }


const PasswordReset: React.FC<PasswordResetProps> = () => {

    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const { addNotificationError, addNotificationSuccess } = useNotification()
    const dispatch = useDispatch();

    const { forgotPassword } = useUser();

    const [email, setEmail] = useState(user.email ?? '');
    const [emailSent, setEmailSent] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [password, setPassword] = useState("");

    const token = searchParams.get('token') ?? '';

    const sendForgotPasswordEmail = () => {
        Client_.client_().forgotPassword(email).then(({ err, res }) => {
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                setEmailSent(true);
            }
        });
    };

    const resetPassword = () => {
        Client_.client_().resetPassword(token, password).then(({ err, res }) => {
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                addNotificationSuccess("Password reset successfully")
                navigate('/login');
            }
        });
    };


    const initReset = () => {
        return emailSent ?
            <>
                <CheckCircleOutlineIcon sx={{ marginTop: 2, fontSize: 50 }} />
                <h2 style={{ marginTop: 20 }}>Email sent!</h2>
                <p>Check your email and open the link we sent to continue</p>
            </>
            :
            <>
                <h1>Password Reset</h1>
                <p>Enter your email and we'll send you a link to reset your password</p>
                <TextField
                    style={{ width: '50%' }}
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                />
                <Fab style={{ marginTop: 20, width: '15%' }} variant="extended" color="primary" size="medium" onClick={() => sendForgotPasswordEmail()}>Send link to email</Fab>
            </>
    }

    const acceptReset = () => {
        return (
            <>
                <h1>Password Reset</h1>
                <TextField
                    style={{ marginTop: 20, width: '50%' }}
                    id="outlined-basic"
                    label="New Password"
                    variant="outlined"
                    type={"password"}
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    helperText={Constants.passwordRequirements}
                />
                <Fab style={{ marginTop: 20, width: '15%' }} variant="extended" color="primary" size="medium" onClick={() => resetPassword()}>Reset password</Fab>
            </>
        )
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {token ? acceptReset() : initReset()}
        </div>
    );
};

export default PasswordReset;