import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useState } from "react";

interface HelpTextProps {
    helpTextId: string;
}

interface HelpTextKeyProps {
    [key: string]: {
        title: string;
        text: string;
    };
}


const helpText: HelpTextKeyProps = {
    twitterSort: {
        title: "Twitter Sort",
        text:
            "Select how to sort the tweets.\n\nThere are two primary things to sort on, popularity (or virality), and the overall rank of the tweet compared to the rest of a user's tweets.\n\nThe default is to sort by best, which sorts first on rank then popularity.\n\nHot sorts first on popularity then rank.\n\nTop sorts by a combined score of number_of_likes + number_of_retweets\n\nNew is sorted based on most recent, then rank.\n\nRandom is a random subsample of tweets from the users' profiles sorted by rank then popularity.\n\nWorst is the user's worst ranked tweets.\n\nPopularity lets you provide a lower and upper bound to sort tweets by popularity.\n\nUser lets you specify a user to sort tweets by popularity.",
    },
    twitterLookback: {
        title: "Twitter Lookback",
        text:
            "This is the number of days to look back for tweets.\n\nThe default is to look back in the last month.",
    },
};

const HelpText: React.FC<HelpTextProps> = ({ helpTextId }) => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <>
            {/* <Tooltip title="Help"> */}
                <IconButton onClick={handleDrawerToggle} sx={{ mr: 2 }}>
                    <HelpOutlineIcon />
                </IconButton>
            {/* </Tooltip> */}
            <Drawer
                open={drawerOpen}
                onClose={handleDrawerToggle}
                variant="temporary"
                anchor='right'
                sx={{
                    width: "30%",
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: '30%',
                        boxSizing: 'border-box',
                    },
                    zIndex: 10000,
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "20px",
                    }}
                >
                    <Typography variant="h6" sx={{ mr: 2 }}>
                        {helpText[helpTextId].title}
                    </Typography>
                    <IconButton onClick={handleDrawerToggle}>
                        <HelpOutlineIcon />
                    </IconButton>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "20px",
                    }}
                >
                    <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}> {helpText[helpTextId].text} </Typography>
                </div>
            </Drawer>
        </>
    );
};

export default HelpText;
