import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';

interface RouteChangeTrackerProps {
    location: any;
}

const RouteChangeTracker: React.FC<RouteChangeTrackerProps> = ({ location }) => {
    useEffect(() => {
        if (location) {
            ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
        }
    }, [location]);

    return null;
};

export default RouteChangeTracker;