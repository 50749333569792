import { Link as MuiLink } from "@mui/material";
// import { LinkProps } from '@mui/material/Link';
// import { Link as MuiLink } from '@mui/material/Link';
import { FC } from "react";
import { Link as ReactRouterLink } from "react-router-dom";

const Link: FC<any> = props => {
    return (
        <MuiLink {...props} component={ReactRouterLink} />
    );
};

export default Link;