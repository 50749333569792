
import DescriptionIcon from '@mui/icons-material/Description';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import React from "react";
import Link from '../Link';
import { useNavigate } from 'react-router-dom';
interface ProductProps {

}

export const getSpotifyProducts = (navigate: any) => {
    return (
        <List>
            <ListItem secondaryAction={
                <IconButton edge="end" onClick={() => window.open('https://x.com/saarimrahman/status/1711553636759957696')}>
                    <DescriptionIcon />
                </IconButton>
            }>
                <Link to='/find-events'><ListItemText primary="Find Concerts & Events" secondary='Find local concerts & events based on who you have saved to your Spotify playlist.' /></Link>
            </ListItem>
            <ListItem secondaryAction={
                <IconButton edge="end" onClick={() => window.open('https://x.com/saarimrahman/status/1713651311017885949')}>
                    <DescriptionIcon />
                </IconButton>
            }>
                <Link to='/find-new-music'><ListItemText primary="Discover New Music" secondary='Discover new, undiscovered music that is not already in your Spotify playlist.' /></Link>
            </ListItem>
            <ListItem secondaryAction={
                <IconButton edge="end" onClick={() => window.open('https://x.com/saarimrahman/status/1722769873288462502')}>
                    <DescriptionIcon />
                </IconButton>
            }>
                <Link to='/set-playlist'><ListItemText primary="Create Set Playlist" secondary='Create a Spotify playlist from a tracklist.'/></Link>
            </ListItem>
            <ListItem>
                <Link to='/find-duplicates'><ListItemText primary="Remove Duplicate Songs" secondary='Remove duplicate songs from your playlist.' /></Link>
            </ListItem>
            <ListItem secondaryAction={
                <IconButton edge="end" onClick={() => window.open('https://x.com/saarimrahman/status/1765476145179611401')}>
                    <DescriptionIcon />
                </IconButton>
            }>
                <Link to='/count-artists'><ListItemText primary="Count Artist Tracks" secondary='See how many songs per artist you have added to your playlist.' /></Link>
            </ListItem>
        </List>
    )
}

export const getProducts = (navigate: any) => {
    return (
        <List>
            <ListItem>
                <Link to='/sr-ai'><ListItemText primary="AI Saarim Chat" secondary="Use a ChatGPT-like interface to chat with me and my resume." /></Link>
            </ListItem>
            <ListItem>
                <Link to='/resume'><ListItemText primary="Resume Builder" secondary='Create a resume with AI.' /></Link>
            </ListItem>
            {getSpotifyProducts(navigate)}
            <ListItem secondaryAction={
                <IconButton edge="end" onClick={() => window.open('https://twitter.com/saarimrahman/status/1645154330289541121')}>
                    <DescriptionIcon />
                </IconButton>
            }>
                <Link to='/twitter-chat'><ListItemText primary="AI Twitter User Chat" secondary="Use a ChatGPT-like interface to chat with someone's tweets." /></Link>
            </ListItem>
            <ListItem secondaryAction={
                <IconButton edge="end" onClick={() => window.open('https://twitter.com/saarimrahman/status/1641911677711990785')}>
                    <DescriptionIcon />
                </IconButton>
            }>
                <Link to='/prompt-helper'><ListItemText primary="LLM Prompt Helper" secondary='Generate and improve prompts to give an LLM for a specific task.' /></Link>
            </ListItem>
            <ListItem secondaryAction={
                <IconButton edge="end" onClick={() => window.open('https://twitter.com/saarimrahman/status/1639124725367709701')}>
                    <DescriptionIcon />
                </IconButton>
            }>
                <Link to='/twitter'><ListItemText primary="Tweet Ranker" secondary='Find and sort tweets for any number of Twitter handles.' /></Link>
            </ListItem>
            <ListItem secondaryAction={
                <IconButton edge="end" onClick={() => window.open('https://twitter.com/saarimrahman/status/1653646128888561664')}>
                    <DescriptionIcon />
                </IconButton>
            }>
                <Link to='/crime-map'><ListItemText primary="SF Crime Map" secondary='Visualize crime data in SF. See how likely a car breakin is for where you parked.' /></Link>
            </ListItem>
            <ListItem secondaryAction={
                <IconButton edge="end" onClick={() => window.open('https://twitter.com/saarimrahman/status/1647326385936175104')}>
                    <DescriptionIcon />
                </IconButton>
            }>
                <Link to='/split-bills'><ListItemText primary="Bill Splitter" secondary='Upload a receipt to split the bill amongst your friends.' /></Link>
            </ListItem>
            <ListItem secondaryAction={
                <IconButton edge="end" onClick={() => window.open('https://x.com/saarimrahman/status/1762366818612867571')}>
                    <DescriptionIcon />
                </IconButton>
            }>
                <Link to='/cartinese'><ListItemText primary="Playboi Carti Translator" secondary='Convert anything to Cartinese.' /></Link>
            </ListItem>
        </List>
    )
}



const ProductPage: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            // height: "100vh",
        }}>
            <h1>Products</h1>
            {getProducts(navigate)}
        </div>
    );
};

export default ProductPage;
