import FmdGoodIcon from '@mui/icons-material/FmdGood';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import LensIcon from '@mui/icons-material/Lens';
import { List, ListItemText } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemButton from '@mui/material/ListItemButton';
import TextField from '@mui/material/TextField';
import bbox from '@turf/bbox';
import { useEffect, useRef, useState } from 'react';
import type { HeatmapLayer, MapRef } from 'react-map-gl';
import Map, { Layer, Marker, Popup, Source } from 'react-map-gl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Client_ from '../../api/Client';
import { useNotification } from '../../stores/actions/StoreActions';
/* eslint-disable import/no-webpack-loader-syntax */
import mapboxgl from 'mapbox-gl';
// @ts-ignore
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

type CrimeData = {
    'Incident ID': number;
    'Latitude': number;
    'Longitude': number;
    'Incident Description': string;
    'Incident Category': string;
    'Incident Date': string;
    'Incident Time': string;
};

interface ICrimeMapProps {

}

const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoiYWF5c3VzIiwiYSI6ImNsaDQ3aWd3OTFnd24zZHBxNDM3YnV2ZGIifQ.octQiW3i6npkVHwT_acvbA'
const MAPBOX_SESSION_TOKEN = '026a67aa-768a-4bc1-887d-510d1d200a26'

const Legend = () => {
    const gradientStyle = {
        height: '10px',
        width: '100%',
        background:
            'linear-gradient(to right, #440154, #3e4989, #21918c, #5ec962, #fde725)',
    };

    const labelStyle = {
        fontSize: '12px',
        color: '#333',
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={gradientStyle} />
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div style={labelStyle}>Least</div>
                <div style={labelStyle}>Middle</div>
                <div style={labelStyle}>Most</div>
            </div>
        </div>
    );
};

const CrimeMap: React.FC<ICrimeMapProps> = () => {
    const mapRef = useRef<MapRef>(null);
    const [crimeData, setCrimeData] = useState<CrimeData[]>([]);
    const [selectedCrime, setSelectedCrime] = useState<CrimeData | null>(null);
    const [lookbackDays, setLookbackDays] = useState<any>(14);
    const [descriptionSubString, setDescriptionSubString] = useState<string>('Theft, From Locked Vehicle');
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [searchSuggestions, setSearchSuggestions] = useState<any[]>([]);
    const [searchResult, setSearchResult] = useState<any>(null);
    const [destination, setDestination] = useState<any>(null);
    const [resultSelected, setResultSelected] = useState(false);
    const [coordinates, setCoordinates] = useState<any>(null);
    const [heatmapData, setHeatmapData] = useState<any>(null);
    const [crimeProbability, setSetCrimeProbability] = useState<any>(null);


    const MAX_ZOOM_LEVEL = 16;
    const MAX_LOCATION_ZOOM = 15;
    const heatmapLayer: HeatmapLayer = {
        id: 'heatmap',
        maxzoom: MAX_ZOOM_LEVEL,
        type: 'heatmap',
        paint: {
            // Increase the heatmap weight based on frequency and property magnitude
            'heatmap-weight': ['interpolate', ['linear'], ['get', 'mag'], 0, 0, 6, 1],
            // Increase the heatmap color weight weight by zoom level
            // heatmap-intensity is a multiplier on top of heatmap-weight
            'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, MAX_ZOOM_LEVEL, 3],
            // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
            // Begin color ramp at 0-stop with a 0-transparancy color
            // to create a blur-like effect.
            'heatmap-color': [
                'interpolate',
                ['linear'],
                ['heatmap-density'],
                0,
                'rgba(0, 0, 0, 0)',
                0.2,
                '#440154',
                0.4,
                '#3e4989',
                0.6,
                '#21918c',
                0.8,
                '#5ec962',
                1,
                '#fde725'
            ],
            // Adjust the heatmap radius by zoom level
            'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, MAX_ZOOM_LEVEL, 20],
            // Transition from heatmap to circle layer by zoom level
            'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 1, MAX_ZOOM_LEVEL, 0]
        },
        source: 'crime-heatmap',
    };



    let navigate = useNavigate();
    const { addNotificationError, addNotification } = useNotification()
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);


    const getCrimeData = () => {
        setIsLoading(true);
        Client_.client_().getCrimeData(parseInt(lookbackDays), descriptionSubString, coordinates?.latitude, coordinates?.longitude).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err);
                return;
            } else {
                setCrimeData(res?.result?.crime_data);
                setHeatmapData(res?.result?.crime_heatmap);
                setSetCrimeProbability(res?.result?.crime_probability);
            }
        });
    };

    // useEffect(() => {
    //     if (crimeProbability) {
    //         addNotification(`Crime Probability: ${crimeProbability}% at latitude: ${coordinates?.latitude} and longitude: ${coordinates?.longitude}`);
    //     }
    // }, [crimeProbability]);



    useEffect(() => {
        if (searchQuery !== "") {
            const fetchSuggestions = async () => {
                const response = await fetch(
                    `https://api.mapbox.com/search/searchbox/v1/suggest?q=${searchQuery}&language=en&access_token=${MAPBOX_ACCESS_TOKEN}&session_token=${MAPBOX_SESSION_TOKEN}`
                );
                const data = await response.json();
                setSearchSuggestions(data?.suggestions);
            };
            fetchSuggestions();
        }
    }, [searchQuery]);


    const handleGetLocation = () => {
        setIsLoading(true);
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                setCoordinates({ latitude, longitude });

                const [minLng, minLat, maxLng, maxLat] = bbox({
                    type: 'Point',
                    coordinates: [longitude, latitude]
                });

                if (mapRef.current) {
                    mapRef.current.fitBounds(
                        [
                            [minLng, minLat],
                            [maxLng, maxLat]
                        ],
                        { padding: 40, duration: 1000, maxZoom: MAX_LOCATION_ZOOM }
                    );
                }
                setIsLoading(false);
            },
            (error) => {
                console.log(error);
                setIsLoading(false);
            }
        );
    };

    useEffect(() => {
        if (searchResult) {
            setSearchQuery(searchResult.full_address)
            const fetchSearchResult = async () => {
                const response = await fetch(
                    `https://api.mapbox.com/search/searchbox/v1/retrieve/${searchResult.mapbox_id}?access_token=${MAPBOX_ACCESS_TOKEN}&session_token=${MAPBOX_SESSION_TOKEN}`
                );
                const data = await response.json();
                const feature = data?.features[0];
                // setDestination(feature?.properties);
                setCoordinates(feature?.properties?.coordinates);
                if (feature && mapRef.current) {
                    const [minLng, minLat, maxLng, maxLat] = bbox(feature);
                    mapRef.current.fitBounds(
                        [
                            [minLng, minLat],
                            [maxLng, maxLat]
                        ],
                        { padding: 40, duration: 1000, maxZoom: MAX_LOCATION_ZOOM }
                    );
                }
            };
            fetchSearchResult();
            setResultSelected(true);
        }
        console.log(searchSuggestions)
    }, [searchResult]);

    useEffect(() => {
        document.title = 'SF Crime';
        getCrimeData();
    }, [])

    const handleSearchQueryChange = (event: any) => {
        setSearchQuery(event.target.value);
        setResultSelected(false);
        setSetCrimeProbability(null);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h1 style={{ textAlign: 'center' }}>San Francisco Crime Map</h1>
            <div style={{ marginBottom: 2, display: 'flex', alignItems: 'center' }}>
                <TextField
                    id="outlined-basic"
                    label="Lookback Days"
                    variant="outlined"
                    value={lookbackDays}
                    placeholder="7"
                    helperText="Number of days to look back for crime data"
                    onChange={(event) => setLookbackDays(event.target.value)}
                    sx={{ mr: 2 }}
                />

                <TextField
                    id="outlined-basic"
                    label="Description Substring"
                    variant="outlined"
                    value={descriptionSubString}
                    placeholder="Theft, From Locked Vehicle"
                    helperText="Only show crimes with this substring in the description"
                    onChange={(event) => setDescriptionSubString(event.target.value)}
                    sx={{ mr: 2 }}
                />

                <div style={{ position: 'relative', display: 'inline-block' }}>
                    <TextField
                        id="outlined-basic"
                        label="Search"
                        variant="outlined"
                        value={searchQuery}
                        helperText="Search for a location"
                        placeholder='Search for a location'
                        onChange={(event) => handleSearchQueryChange(event)}
                        sx={{ mr: 2, width: 300 }}
                    />
                    {searchSuggestions?.length > 0 && !resultSelected && (
                        <List sx={{ position: 'absolute', zIndex: 1, bgcolor: 'background.paper', width: 'calc(100% - 40px)', boxShadow: 3 }}>
                            {searchSuggestions.map((result) => (
                                <ListItemButton key={result.mapbox_id} onClick={() => setSearchResult(result)}>
                                    <ListItemText primary={result.name} secondary={result.full_address} />
                                </ListItemButton>
                            ))}
                        </List>
                    )}
                </div>

                <Button variant="contained" sx={{ mb: 3, mr: 2 }} onClick={() => handleGetLocation()}
                    startIcon={<GpsFixedIcon />}
                >Find Me</Button>


                {isLoading ?
                    <CircularProgress sx={{ mb: 3 }} />
                    :
                    <Button variant="contained" sx={{ mb: 3 }} onClick={() => getCrimeData()} >Search</Button>
                }

                {crimeProbability != null &&
                    <p style={{ marginBottom: 30, marginLeft: 10, color: 'red' }}>
                        Crime Probability: {crimeProbability}%
                    </p>
                }
            </div>
            <Map
                initialViewState={{
                    longitude: -122.4474,
                    latitude: 37.7529,
                    zoom: 12
                }}
                mapStyle="mapbox://styles/mapbox/streets-v12"
                // mapStyle='mapbox://styles/mapbox/dark-v10'
                style={{ width: '95vw', height: '90vh' }}
                mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
                ref={mapRef}
            >
                {crimeData.map(crime => (
                    <Marker
                        key={crime['Incident ID']}
                        latitude={crime['Latitude']}
                        longitude={crime['Longitude']}
                        onClick={(e) => {
                            e.originalEvent.stopPropagation();
                            setSelectedCrime(crime)
                        }}
                        style={{ cursor: 'pointer' }}
                    >
                        <LensIcon style={{ color: 'red', opacity: 0.2, fontSize: 12 }} />
                    </Marker>
                ))}
                {heatmapData && (
                    <Source type="geojson" data={heatmapData}>
                        <Layer {...heatmapLayer} />
                    </Source>
                )}
                {destination && (
                    <Marker
                        key={destination.mapbox_id}
                        latitude={destination.coordinates.latitude}
                        longitude={destination.coordinates.longitude}
                    // anchor='bottom'
                    >
                        <FmdGoodIcon style={{ color: 'green', fontSize: 30 }} />
                    </Marker>
                )}

                {coordinates && (
                    <Marker
                        key={'currentLocation'}
                        latitude={coordinates.latitude}
                        longitude={coordinates.longitude}
                    // anchor='bottom'
                    >
                        <FmdGoodIcon style={{ color: 'green', fontSize: 30 }} />
                    </Marker>
                )}
                {selectedCrime && (
                    <Popup
                        latitude={selectedCrime['Latitude']}
                        longitude={selectedCrime['Longitude']}
                        // anchor='top'
                        onClose={() => setSelectedCrime(null)}
                        style={{ color: 'black' }}
                    >
                        <b>{selectedCrime['Incident Category']}</b> <br />
                        {selectedCrime['Incident Description']} <br />
                        <span style={{ color: 'gray', fontSize: 10 }}>{selectedCrime['Incident Date']} {selectedCrime['Incident Time']} </span>
                    </Popup>
                )}
            </Map>
        </div>
    );
};


export default CrimeMap;
