import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Client_ from '../../api/Client';
import constants from '../../constants/Constants';
import { useNotification, useUser } from '../../stores/actions/StoreActions';
import Link from '../Link';

interface SpotifyProps { }


const Spotify: React.FC<SpotifyProps> = () => {
    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const { addNotificationError, addNotificationSuccess, addNotification } = useNotification()
    const dispatch = useDispatch();

    const { forgotPassword } = useUser();

    const [playlistUrl, setPlaylistUrl] = useState("");
    const [limit, setLimit] = useState(50);
    const [generatedPlaylistUrl, setGeneratedPlaylistUrl] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [needsSpotifyLogin, setNeedsSpotifyLogin] = useState(false);

    useEffect(() => {
        document.title = 'Find New Music';
        // Define a function to handle the Spotify authentication check
        const checkSpotifyAuth = async () => {
            const { err, res } = await Client_.client_().validateSpotifyAuth();
            if (err || !res?.success) {
                addNotificationError(err);
            } else {
                if (res?.result?.success) {
                    setNeedsSpotifyLogin(false);
                } else {
                    addNotificationError('Please login to Spotify first');
                    setNeedsSpotifyLogin(true);
                    navigate('/spotify-auth');
                }
            }
        };
        checkSpotifyAuth();
        const intervalId = setInterval(checkSpotifyAuth, 30000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);




    const createNewAndSimilarPlaylist = (playlistUrl: string, limit: number) => {
        setIsLoading(true);
        setGeneratedPlaylistUrl('');
        Client_.client_().createNewAndSimilarPlaylist(playlistUrl, limit).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                setGeneratedPlaylistUrl(res?.result?.playlist_url);
            }
        });
    };


    const setExampleData = () => {
        setLimit(50);
        setPlaylistUrl(constants.exampleSpotifyUrl);
        createNewAndSimilarPlaylist(constants.exampleSpotifyUrl, 50);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <h1 style={{textAlign: 'center'}}>Find New & Undiscovered Songs</h1>
            {needsSpotifyLogin &&
                <p style={{ color: 'red', fontWeight: 'bold', fontSize: 20 }}>Please login to Spotify first, click <Link to='/spotify-auth' target='_blank' rel='noreferrer'>here</Link> if not redirected automatically.</p>
            }
            <p>Enter the URL of the Spotify playlist you want to generate new, undiscovered music for</p>
            <p>Stuck? Right click your playlist {'>'} Share {'>'} Copy link to playlist</p>
            <TextField
                sx={{ width: '75%', mb: 2 }}
                id="outlined-basic"
                label="Playlist URL"
                variant="outlined"
                value={playlistUrl}
                onChange={(event) => setPlaylistUrl(event.target.value)}
            />
            <TextField
                sx={{ width: '75%', mb: 2 }}
                id="outlined-basic"
                label="Number of songs to generate"
                variant="outlined"
                value={limit}
                type="number"
                onChange={(event) => setLimit(parseInt(event.target.value))}
            />
            <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                {isLoading ?
                    <CircularProgress />
                    :
                    <>
                        <Button variant="contained" color="primary" size="medium" onClick={() => createNewAndSimilarPlaylist(playlistUrl, limit)}>Generate Playlist</Button>
                        <Button variant='contained' color='secondary' onClick={() => setExampleData()} sx={{ ml: 2 }}>Use Example</Button>
                    </>

                }
            </div>

            {generatedPlaylistUrl &&
                <>
                    <hr style={{ width: '75%', marginTop: 20 }} />
                    <h2 style={{ marginTop: 10 }}>Here is your generated playlist:</h2>
                    <Link to={generatedPlaylistUrl} target="_blank" rel="noreferrer">{generatedPlaylistUrl}</Link>
                </>
            }
        </div>
    );
};

export default Spotify;