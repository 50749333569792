import { createSlice } from "@reduxjs/toolkit";

export interface UserState {
    username: string;
    email: string;
    first_name: string;
    isLoading: boolean;
}

const initialState: UserState = {
    username: '',
    email: '',
    first_name: '',
    isLoading: false,
}


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state: any, action: any) => ({
            ...state,
            ...action.payload,
            isLoading: false,
        }),
        setUserLoading: (state: any) => ({
            ...state,
            isLoading: true,
        }),
        registerUser: (state: any, action: any) => ({
            ...state,
            email: action.payload.email,
            username: action.payload.username,
            first_name: action.payload.firstName,
            isLoading: true,
        }),
    }
})

export const userActions = userSlice.actions;
export const userReducer = userSlice.reducer;