import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as React from 'react';
import Link from './components/Link';
import { getProducts } from './components/Product/Product';
import { useNavigate } from 'react-router-dom';

import './App.css';


function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const navigate = useNavigate();

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );

  return (

      <div className="App">
        <header className="App-header">
          <h1>Hi there!</h1>
          <p style={{fontSize: 20}}>My name is Saarim. I enjoy building things. To learn more about me, head to my <Link to='https://saarim.me'>homepage</Link>.</p>
          <span style={{fontSize: 14, color: 'grey'}}>For the best possible experience, use Desktop.</span>
          <p style={{fontSize: 20}}>Here are some cool things I've built:</p>
        {getProducts(navigate)}

        {/* <p><Link to="/login">Log in</Link> or <Link to="/register">Register</Link></p> */}
        </header>

      </div>

      /* <div className="App">
        <header className="App-header">
          <Login />
          <Register />
          <img src={logo} className="App-logo" alt="logo" />
          <Counter />
          <p>
            Edit <code>src/App.tsx</code> and save to reload.
          </p>
          <span>
            <span>Learn </span>
            <a
              className="App-link"
              href="https://reactjs.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              React
            </a>
            <span>, </span>
            <a
              className="App-link"
              href="https://redux.js.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Redux
            </a>
            <span>, </span>
            <a
              className="App-link"
              href="https://redux-toolkit.js.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Redux Toolkit
            </a>
            ,<span> and </span>
            <a
              className="App-link"
              href="https://react-redux.js.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              React Redux
            </a>
          </span>
        </header>
      </div> */
  );
}

export default App;
