import AddBoxIcon from '@mui/icons-material/AddBox';
import CloudUpload from '@mui/icons-material/CloudUpload';
import { default as Delete, default as DeleteIcon } from '@mui/icons-material/Delete';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import 'chart.js/auto';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Client_ from '../../api/Client';
import { useNotification } from '../../stores/actions/StoreActions';


const CHART_BACKGROUND_COLORS = [
    'rgba(255, 99, 132, 0.8)',
    'rgba(54, 162, 235, 0.8)',
    'rgba(255, 206, 86, 0.8)',
    'rgba(75, 192, 192, 0.8)',
    'rgba(153, 102, 255, 0.8)',
    'rgba(255, 159, 64, 0.8)',
];


const sampleData = {
    "items": [{ "id": 0, "item": "Pint Jerryburst", "price": 7.95 }, { "id": 1, "item": "Pint Jerryburst", "price": 7.95 }, { "id": 2, "item": "Loaded Nachos", "price": 20.95 }, { "id": 3, "item": "Pint Jerryburst", "price": 7.95 }, { "id": 4, "item": "Pint Jerryburst", "price": 7.95 }, { "id": 5, "item": "Pint Jerryburst", "price": 7.95 }, { "id": 6, "item": "Subtotal", "price": 60.7 }, { "id": 7, "item": "Tax", "price": 5.31 }, { "id": 8, "item": "Check Total", "price": 66.01 }, { "id": 9, "item": "15% Tip TYPE IN TIP", "price": 9 }, { "id": 10, "item": "PAYMENT AMOUNT", "price": 75.01 }],
    "users": { "0": ["saarim"], "1": ["saarim"], "2": ["saarim", "victor", "riley"], "3": ["riley"], "4": ["riley"], "5": ["riley"] },
    "ids": { "6": "Subtotal", "7": "Tax", "9": "Tip", "10": "Total" },
    "people": ['saarim', 'victor', 'riley']
}

const BillSplitter: React.FC = () => {
    const [file, setFile] = useState<any>(null);
    const [items, setItems] = useState<any>([]);

    const [isLoading, setIsLoading] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState<{ [itemId: number]: string[] }>({});
    const [selectedItemTypes, setSelectedItemTypes] = useState<{ [itemId: number]: string }>({});
    const [imageSrc, setImageSrc] = useState<any>(null);

    const { addNotificationError, addNotificationSuccess } = useNotification();
    const [inputValue, setInputValue] = useState('');
    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const [users, setUsers] = useState<string[]>([]);
    const [billSplit, setBillSplit] = useState<any>(null);
    const [selectedItemId, setSelectedItemId] = useState<any>(null);
    const [numPeriods, setNumPeriods] = useState(1);


    const itemTypes = ['Tax', 'Tip', 'Subtotal', 'Total', 'Item']


    useEffect(() => {
        document.title = 'Split Bills';
    }, []);

    const useSampleData = () => {
        setItems(sampleData.items)
        setSelectedUsers(sampleData.users)
        setSelectedItemTypes(sampleData.ids)
        setUsers(sampleData.people)
    }

    const handleAddUser = (event: any) => {
        const newUser = event.target.value.trim();
        if (newUser !== "" && !users.includes(newUser)) {
            setUsers([...users, newUser]);
            event.target.value = "";
        } else if (users.includes(newUser)) {
            event.target.value = "";
        }
    };

    const handleDeleteUser = (userToDelete: string) => {
        const updatedUsers = users.filter((user) => user !== userToDelete);
        setUsers(updatedUsers);
    };

    const handleFileChange = (event: any) => {
        handleClear();
        setFile(event.target.files[0]);
        setImageSrc(URL.createObjectURL(event.target.files[0]));
    };

    const handleFileUpload = async () => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('img', file);
        Client_.client_().getItemsFromReceipt(formData).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                setItems(res?.result?.items)
                setSelectedItemTypes(res?.result?.item_types)
            }
        });
    };



    const splitBill = async () => {
        console.log(selectedUsers);
        setIsLoading(true);
        Client_.client_().splitBill(items, selectedUsers, selectedItemTypes).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                addNotificationSuccess("Bill split successfully! Scroll down to see the results.")
                setBillSplit(res?.result)
            }
        });
    };


    const handleClear = () => {
        setFile(null);
        setItems([]);
        setBillSplit(null);
        setSelectedUsers({});
        setSelectedItemTypes({});
        setImageSrc(null);
        setUsers([]);
    };

    const handleAddRow = () => {
        const newId = items.reduce((maxId: any, item: any) => Math.max(maxId, item.id), -1) + 1;
        setItems([...items, { id: newId, item: '', price: 0, purchased_by: [] }]);
    };

    const handleDeleteRow = (itemId: any) => {
        setItems((prevItems: any) => prevItems.filter((prevItem: any) => prevItem.id !== itemId));
    };


    const barOptions = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context: any) => {
                        const label = context.label || '';
                        if (label) {
                            let value = context.formattedValue || context.raw;
                            return `${label}: $${value}`;
                        }
                        return '';
                    },
                },
            },
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                fontColor: 'white',
            },
        },
        responsive: true,
        maintainAspectRatio: false,
    };

    const doughnutOptions = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context: any) => {
                        const label = context.label || '';
                        if (label) {
                            let value = context.formattedValue || context.raw;
                            value = (value * 100).toFixed(2);
                            return `${label}: ${value}%`;
                        }
                        return '';
                    },
                },
            },
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                fontColor: 'white',
            },
        },
        responsive: true,
        maintainAspectRatio: false,
    };


    return (
        <Paper sx={{ p: 2 }}>
            <h1 style={{ textAlign: 'center' }}>Bill Splitter</h1>
            <p style={{ textAlign: 'center' }}>Upload a receipt to extract items and quickly split the bill with your friends!</p>

            <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                    type="file"
                    accept=".jpg,.png,.jpeg"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    id="file-upload-button"
                />
                <label htmlFor="file-upload-button">
                    <Tooltip title="Upload a receipt" placement="top">
                        <Button component="span" variant="contained" startIcon={<CloudUpload />}
                            sx={{ mr: 2 }}>
                            Upload
                        </Button>
                    </Tooltip>
                </label>

                <Tooltip title="Clear all fields and start over" placement="top">
                    <Button variant="contained" startIcon={<Delete />} onClick={handleClear}
                        sx={{ mr: 2 }}>
                        Clear
                    </Button>
                </Tooltip>


                <Tooltip title="Use sample receipt data to test the bill splitter's functionality" placement="top">
                    <Button variant='contained' onClick={useSampleData} sx={{}}
                        startIcon={<LibraryBooksIcon />}
                    >
                        Use Sample Data
                    </Button>
                </Tooltip>

                {isLoading ?
                    <CircularProgress size='4vh' sx={{ ml: 2 }} />
                    :
                    <Tooltip title="Extract the receipt items from the uploaded receipt" placement="top">
                        <Button variant="contained" onClick={handleFileUpload} disabled={!file} sx={{ml: 2}}
                            startIcon={<ImageSearchIcon />}>
                            Extract Items
                        </Button>
                    </Tooltip>
                }
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {file && (
                    <Typography variant="body1" sx={{ mt: 2 }}>
                        Selected file: {file.name}
                    </Typography>
                )}
                {imageSrc && (
                    <img src={imageSrc} alt='uploaded file' style={{ maxWidth: '500px', maxHeight: '500px' }} />
                )}
            </div>

            <div style={{ display: "flex", alignItems: "center", marginTop: 15 }}>
                <TextField
                    label="People"
                    variant="outlined"
                    placeholder="Add a person"
                    helperText='Enter a person who is splitting the bill and press "Enter". You can add multiple people.'
                    onKeyUp={(e) => e.key === "Enter" && handleAddUser(e)}
                />
                {users.map((user) => (
                    <Chip
                        key={user}
                        label={user}
                        onDelete={() => handleDeleteUser(user)}
                        style={{ margin: "0 5px" }}
                    />
                ))}
            </div>

            {(items?.length > 0 && (
                <>
                    <h2>Receipt Items</h2>
                    <TableContainer component={Paper} sx={{ mt: 2 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell>ID</TableCell> */}
                                    <TableCell>Item</TableCell>
                                    <TableCell>Price</TableCell>
                                    <TableCell>People</TableCell>
                                    <TableCell>Item Type</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items?.map((item: any) => (
                                    <TableRow key={item?.id}>
                                        {/* <TableCell>{item?.id}</TableCell> */}
                                        <TableCell>
                                            <TextField
                                                value={item?.item}
                                                onChange={(event) => {
                                                    const value = event.target.value;
                                                    setItems((prevItems: any) =>
                                                        prevItems.map((prevItem: any) => (prevItem.id === item.id ? { ...prevItem, item: value } : prevItem))
                                                    );
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                value={item?.price}
                                                onChange={(event) => {
                                                    const value = event.target.value;
                                                    setItems((prevItems: any) =>
                                                        prevItems.map((prevItem: any) => (prevItem.id === item.id ? { ...prevItem, price: value } : prevItem))
                                                    );
                                                }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <FormControl sx={{ minWidth: '120px' }}>
                                                <Select
                                                    multiple
                                                    value={selectedUsers[item.id] || []}
                                                    onChange={(event) => {
                                                        const value = event.target.value as string[];
                                                        setSelectedUsers({ ...selectedUsers, [item.id]: value });
                                                    }}
                                                    renderValue={(selected) => selected.join(', ')}
                                                >
                                                    {users?.map((user) => (
                                                        <MenuItem key={user} value={user}>
                                                            {user}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell>
                                            <FormControl sx={{ minWidth: '120px' }}>
                                                <Select
                                                    value={selectedItemTypes[item.id] || ''}
                                                    onChange={(event) => {
                                                        const value = event.target.value as string;
                                                        setSelectedItemTypes({ ...selectedItemTypes, [item.id]: value });
                                                    }}
                                                >
                                                    {itemTypes?.map((itemType) => (
                                                        <MenuItem key={itemType} value={itemType}>
                                                            {itemType}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => handleDeleteRow(item.id)}>
                                                <DeleteIcon sx={{ color: '#FF695E' }} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>


                </>
            ))}
            <Tooltip title="Add a row to the receipt items table" placement="top">
                <Button variant="contained" sx={{ m: 2 }} onClick={handleAddRow}
                    startIcon={<AddBoxIcon />}
                >
                    Add Receipt Item
                </Button>
            </Tooltip>

            {items?.length > 0 && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Tooltip title="Split the bill" placement="top">
                        <Button variant="contained" startIcon={<PriceChangeIcon />} onClick={splitBill} sx={{ m: 2 }}>
                            Split
                        </Button>
                    </Tooltip>
                </Box>
            )}

            {billSplit && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h2>Bill Summary</h2>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        Bill Details
                                    </Typography>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Subtotal</TableCell>
                                                <TableCell align="right">${billSplit.subtotal.toFixed(2)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Tax</TableCell>
                                                <TableCell align="right">${billSplit.tax.toFixed(2)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Tip</TableCell>
                                                <TableCell align="right">${billSplit.tip.toFixed(2)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell><strong>Total</strong></TableCell>
                                                <TableCell align="right"><strong>${billSplit.total.toFixed(2)}</strong></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        Individual Totals
                                    </Typography>
                                    <Table>
                                        <TableBody>
                                            {billSplit.all_users.map((userId: string) => (
                                                <TableRow key={userId}>
                                                    <TableCell>{userId}</TableCell>
                                                    <TableCell align="right">${billSplit.user_totals[userId].toFixed(2)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <h2>Detailed Breakdown</h2>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Person</TableCell>
                                    <TableCell>Total (pre tax + tip)</TableCell>
                                    <TableCell>Proportion of Total</TableCell>
                                    <TableCell>Total (post tax + tip)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {billSplit.all_users.map((userId: any) => (
                                    <TableRow key={userId}>
                                        <TableCell>{userId}</TableCell>
                                        <TableCell>${billSplit.costs[userId].toFixed(2)}</TableCell>
                                        <TableCell>{(billSplit.proportions[userId] * 100).toFixed(2)}%</TableCell>
                                        <TableCell>${billSplit.user_totals[userId].toFixed(2)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <h2>Visualizations</h2>
                    <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 2, mt: 2 }}>
                        <Box sx={{ height: '300px', width: '300px' }}>
                            <Typography variant="subtitle1" align="center">Total Per Person (pre tax & tip)</Typography>
                            <Bar data={{
                                labels: Object.keys(billSplit?.costs),
                                datasets: [
                                    {
                                        label: 'Costs',
                                        data: Object.values(billSplit?.costs),
                                        backgroundColor: CHART_BACKGROUND_COLORS,
                                    },
                                ],
                            }} options={barOptions} />
                        </Box>

                        <Box sx={{ height: '300px', width: '300px' }}>
                            <Typography variant="subtitle1" align="center">Proportion of Total Per Person</Typography>
                            <Bar data={{
                                labels: Object.keys(billSplit?.proportions),
                                datasets: [
                                    {
                                        label: 'Proportions',
                                        data: Object.values(billSplit?.proportions),
                                        backgroundColor: CHART_BACKGROUND_COLORS,
                                    },
                                ],
                            }} options={doughnutOptions} />
                        </Box>

                        <Box sx={{ height: '300px', width: '300px' }}>
                            <Typography variant="subtitle1" align="center">Total Per Person (after tax & tip)</Typography>
                            <Bar data={{
                                labels: Object.keys(billSplit?.user_totals),
                                datasets: [
                                    {
                                        label: 'User Totals',
                                        data: Object.values(billSplit?.user_totals),
                                        backgroundColor: CHART_BACKGROUND_COLORS,
                                    },
                                ],
                            }} options={barOptions} />
                        </Box>
                    </Box>
                </div>
            )}
        </Paper>
    );
};

export default BillSplitter;