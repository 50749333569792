
import Fab from '@mui/material/Fab';
// import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
// import { Link as RouterLink } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from "react-router-dom";
import Constants from '../../constants/Constants';
import { useUser } from '../../stores/actions/StoreActions';
import Link from '../Link';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';



import './Register.css';

interface RegisterProps { }

const Register: React.FC<RegisterProps> = () => {
    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);


    const { registerUser } = useUser();

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = () => {
        registerUser(username, email, password, firstName);
    };

    useEffect(() => {
        if (user?.username) {
            navigate('/login');
        }
    }, [navigate, user]);


    return (
        <div className='register'>
            <p style={{fontWeight: 'bold', fontSize: 40}}>Sign up for free</p>
            <TextField
                style={{ width: '50%' }}
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
            />

            <TextField
                style={{ marginTop: 20, width: '50%' }}
                id="outlined-basic"
                label="Username"
                variant="outlined"
                value={username}
                onChange={(event) => setUsername(event.target.value)}
            />

            <TextField
                style={{ marginTop: 20, width: '50%' }}
                id="outlined-basic"
                label="Email"
                variant="outlined"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
            />

            <TextField
                style={{ marginTop: 20, width: '50%' }}
                id="outlined-basic"
                label="Password"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                helperText={Constants.passwordRequirements}
                InputProps={{ // this is where the toggle button is added
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            <div style={{ fontSize: 10, textAlign: 'center', marginTop: 20 }}>
                <p>By clicking on sign up, you agree to the <Link to='/'>Terms and Conditions of Use</Link>.</p>
                <p>To learn more about how we collect, use, and protect your personal data, please see our <Link to='/'>Privacy Policy</Link>.</p>
            </div>
            {user.isLoading ?
                <CircularProgress sx={{ marginTop: 2 }} />
                :
                <Fab style={{ marginTop: 20, width: '15%' }} variant="extended" color="primary" size="medium" onClick={() => handleSubmit()}>Sign Up</Fab>
            }
            <p>Have an account? <Link to="/login">Log in</Link></p>
        </div>
    );
};

export default Register;