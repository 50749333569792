import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import Client_ from '../../api/Client';
import constants from '../../constants/Constants';
import { useNotification } from '../../stores/actions/StoreActions';
import utils from '../../utils';

interface ChatMessage {
    role: string;
    text: string;
    timestamp: string;
}

const TwitterChat: React.FC = () => {
    const [message, setMessage] = useState('');
    const [user, setUser] = useState('');
    const [chatHistory, setChatHistory] = useState([{}]);
    const [isLoading, setIsLoading] = useState(false);
    const { addNotificationError, addNotificationSuccess } = useNotification();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        document.title = `Twitter Chat`;
    }, []);

    const sendChatMessage = async () => {
        setIsLoading(true);

        const messageCopy = message;
        const chatHistoryCopy = [...chatHistory];
        setMessage('');
        setSearchParams({
            username: user,
        });

        chatHistory.push({
            role: 'user',
            text: message,
            timestamp: new Date().toISOString(),
        })

        document.title = `@${user} | Twitter Chat`;

        if (chatHistory.length === 2 && Object.keys(chatHistory[0]).length === 0) {
            chatHistory.shift();
        };

        Client_.client_().queryTwitterUserChat(user, messageCopy, chatHistoryCopy).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                setChatHistory(res?.result?.chat_history)
            }
        });
    };

    const setTwitterUser = (username: any) => {
        setUser(utils.getTwitterUserFromUrl(username));
        setChatHistory([{}]);
    };


    useEffect(() => {
        let searchUsername = searchParams.get('username') || '';
        setUser(searchUsername);
    }, []);


    const getTwitterUserFromUrl = (url: string) => {
        if (url.indexOf('twitter.com') === -1) {
            return url;
        }
        let username = url.split('twitter.com/')[1].split('/')[0];
        return username;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h1>Twitter Chat</h1>
            <p>{constants.twitterDisclaimer}</p>

            <TextField
                style={{ width: '75%' }}
                id="outlined-basic"
                label="Twitter Handle"
                variant="outlined"
                value={user}
                helperText='Enter the Twitter handle or profile URL of the user you want to chat with. E.g. for @Joe you would enter "Joe"'
                onChange={(event) => setTwitterUser(event.target.value)}
            />

            {/* <div style={{ marginTop: 10, height: 'calc(100vh - 350px)', width: '100%', overflow: 'scroll' }}>
                {chatHistory && Object.keys(chatHistory[0]).length > 0 && chatHistory.map((chatMessage: any, index: number) => (
                    <Box key={index} sx={{ bgcolor: chatMessage.role === 'user' ? '#202020' : '#616160', py: 1, px: 2, borderRadius: '10px', display: 'flex', justifyContent: chatMessage.role === 'user' ? 'flex-end' : 'flex-start' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: chatMessage.role === 'user' ? 'flex-end' : 'flex-start', width: '100%' }}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                {chatMessage.role === 'user' ? 'You: ' : `@${user}: `}
                            </Typography>
                            <Typography variant="body2">
                                {chatMessage.text}
                            </Typography>
                        </Box>
                    </Box>
                ))}
            </div> */}

            <div style={{ marginTop: 10, height: 'calc(100vh - 350px)', width: '100vw', overflow: 'scroll' }}>
                {chatHistory && Object.keys(chatHistory[0]).length > 0 && chatHistory.map((chatMessage: any, index: number) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', bgcolor: chatMessage.role === 'user' ? '#202020' : '#616160', py: 1 }}>
                        <Box sx={{ minWidth: '150px', mr: 2, flexShrink: 0, justifyContent: 'flex-end', textAlign: 'right' }}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold', display: 'inline-block', marginRight: 2 }}>
                                {chatMessage.role === 'user' ? 'Me' : `${user}GPT`}
                            </Typography>
                        </Box>
                        <Box sx={{ maxWidth: '75%', width: '100%', textAlign: 'left' }}>
                            <Typography variant="body1" sx={{ display: 'inline-block' }}>
                                {chatMessage.text}
                            </Typography>
                        </Box>
                    </Box>
                ))}
            </div>

            <div style={{ position: 'fixed', bottom: 5, left: 5, width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Tooltip title="Clear chat">
                    <IconButton onClick={() => setChatHistory([{}])}>
                        <ClearIcon />
                    </IconButton>
                </Tooltip>

                <TextField
                    style={{ width: '75%' }}
                    id="outlined-basic"
                    label="Send a message..."
                    variant="outlined"
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            sendChatMessage();
                        }
                    }}
                    disabled={user === ''}
                    helperText={user === '' ? 'Please enter a Twitter handle or profile URL first' : ''}
                />

                {isLoading ? (
                    <CircularProgress sx={{marginTop: 1, marginLeft: 2}}/>
                ) : (
                    <IconButton color="primary" onClick={sendChatMessage}>
                        <SendIcon />
                    </IconButton>
                )}
            </div>
        </div>
    );
};

export default TwitterChat;
