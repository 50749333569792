import { createBrowserRouter } from 'react-router-dom';
import App from './App';
import BillSplitter from './components/BillSplitter/BillSplitter';
import CrimeMap from './components/CrimeMap/CrimeMap';
import ErrorPage from './components/ErrorPage/ErrorPage';
import FindEvents from './components/FindEvents/FindEvents';
import Login from './components/Login/Login';
import PasswordReset from './components/PasswordReset/PasswordReset';
import Pricing from './components/Pricing/Pricing';
import ProductPage from './components/Product/Product';
import Profile from './components/Profile/Profile';
import PromptHelper from './components/PromptHelper/PromptHelper';
import Register from './components/Register/Register';
import SaarimChat from './components/SaarimChat/SaarimChat';
import SetPlaylist from './components/SetPlaylist/SetPlaylist';
import Spotify from './components/Spotify/Spotify';
import SpotifyAuth from './components/SpotifyAuth/SpotifyAuth';
import SpotifyDuplicates from './components/SpotifyDuplicates/SpotifyDuplicates';
import SpotifyProducts from './components/SpotifyProducts/SpotifyProducts';
import TweetGallery from './components/TweetGallery/TweetGallery';
import TwitterChat from './components/TwitterChat/TwitterChat';
import VerifyEmail from './components/VerifyEmail/VerifyEmail';
import Header from './components/Header/Header';
import Cartinese from './components/Cartinese/Cartinese';
import SpotifyCountArtists from './components/SpotifyCountArtists/SpotifyCountArtists';
import { Outlet } from "react-router-dom";
import CartinesePurchase from './components/CartinesePurchase/CartinesePurchase';
import Resume from './components/Resume/Resume';
import RouteChangeTrackerWrapper from './components/RouteChangeTrackerWrapper';

function Layout() {
    return (
        <>
            <Header />
            <Outlet />
            <RouteChangeTrackerWrapper />
        </>
    );
}

const router = createBrowserRouter([
    {element: <Layout />,
     errorElement: <ErrorPage />,
     children: [
        { path: '/', element: <App /> },
        { path: '/product', element: <ProductPage />},
        { path: '/pricing', element: <Pricing />},
        // User
        { path: '/login', element: <Login /> },
        { path: '/register', element: <Register /> },
        { path: '/password-reset', element: <PasswordReset /> },
        { path: '/verify-email', element: <VerifyEmail /> },
        { path: '/profile', element: <Profile /> },
        // Spotify
        { path: '/spotify', element: <SpotifyProducts />},
        { path: '/spotify-auth', element: <SpotifyAuth />},
        { path: '/find-new-music', element: <Spotify /> },
        { path: '/find-events', element: <FindEvents />},
        { path: '/set-playlist', element: <SetPlaylist />},
        { path: '/find-duplicates', element: <SpotifyDuplicates />},
        { path: '/count-artists', element: <SpotifyCountArtists />},
        // Twitter
        { path: '/twitter', element: <TweetGallery /> },
        { path: '/twitter-chat', element: <TwitterChat /> },
        // Gen AI
        { path: '/prompt-helper', element: <PromptHelper /> },
        { path: '/sr-ai', element: <SaarimChat />},
        // Other
        { path: '/split-bills', element: <BillSplitter />},
        { path: '/crime-map', element: <CrimeMap />},
        { path: '/cartinese', element: <Cartinese />},
        { path: '/cartinese/purchase', element: <CartinesePurchase />},
        // Resume
        { path: '/resume', element: <Resume />},
     ]},
]);

export default router;
