import Button from '@mui/material/Button';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Client_ from '../../api/Client';
import { useNotification } from '../../stores/actions/StoreActions';

interface SpotifyAuthProps { }


const SpotifyAuth: React.FC<SpotifyAuthProps> = () => {

    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const { addNotificationError, addNotificationSuccess, addNotification } = useNotification()
    const dispatch = useDispatch();

    const [playlistUrl, setPlaylistUrl] = useState("");
    const [generatedPlaylistUrl, setGeneratedPlaylistUrl] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [authLink, setAuthLink] = useState("");
    const [code, setCode] = useState("");

    useEffect(() => {
        document.title = 'Spotify Login';
    }, []);

    useEffect(() => {
        let urlParams = new URLSearchParams(window.location.search);
        let code = urlParams.get('code');
        if (code) {
            setIsLoading(true);
            Client_.client_().setSpotifyToken(code).then(({ err, res }) => {
                setIsLoading(false);
                if (err || !res?.success) {
                    addNotificationError(err);
                } else {
                    addNotificationSuccess('Successfully logged in to Spotify');
                    navigate('/spotify');
                }
            });
        };
        Client_.client_().validateSpotifyAuth().then(({ err, res }) => {
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                if (res?.result?.success) {
                    addNotificationSuccess('Logged in to Spotify');
                    navigate('/spotify');
                }
            }
        });
        initSpotifyOAuth();
    }, []);

    const initSpotifyOAuth = () => {
        setIsLoading(true);
        Client_.client_().initSpotifyOAuth().then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                let url = res?.result?.auth_url;
                setAuthLink(url);
            }
        });
    }

    const openSpotifyAuthWindow = (url: string) => {
        const newWindow = window.open(url, '_blank');
        if (newWindow) {
            // The window opened successfully, you can further manipulate it if needed.
        } else {
            // Handle cases where the window couldn't be opened (e.g., due to a popup blocker).
            addNotificationError("Unable to open the Spotify login page. Please check your popup blocker settings.");
        }
    }


    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
            <h1>Spotify Authentication</h1>
            <p>
                To use Spotify related features, you must first login with Spotify.
            </p>
            <Button variant="contained" color="primary" onClick={() => openSpotifyAuthWindow(authLink)} sx={{ mr: 2 }}>
                Login with Spotify
            </Button>
        </div>
    );
};

export default SpotifyAuth;