import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Client_ from '../../api/Client';
import { useNotification } from '../../stores/actions/StoreActions';

interface VerifyEmailProps { }


const VerifyEmail: React.FC<VerifyEmailProps> = () => {

    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const { addNotificationError, addNotificationSuccess } = useNotification()
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();

    const token = searchParams.get('token') ?? '';

    const verifyEmail = () => {
        Client_.client_().verifyEmail(token).then(({ err, res }) => {
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                // setEmailSent(true);
                addNotificationSuccess("Email verified successfully!")
                navigate('/login');
            }
        });
    };

    useEffect(() => {
        verifyEmail();
    });



    // const verifyEmail = () => {
    //     return (
    //         <>
    //             <h1>Password Reset</h1>
    //             <TextField
    //                 style={{ marginTop: 20, width: '50%' }}
    //                 id="outlined-basic"
    //                 label="New Password"
    //                 variant="outlined"
    //                 type={"password"}
    //                 value={password}
    //                 onChange={(event) => setPassword(event.target.value)}
    //                 helperText={Constants.passwordRequirements}
    //             />
    //             <Fab style={{ marginTop: 20, width: '15%' }} variant="extended" color="primary" size="medium" onClick={() => verifyEmail()}>Reset password</Fab>
    //         </>
    //     )
    // }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {/* {token ? verifyEmail() : <h1>An unexpected error occured.</h1>} */}
        </div>
    );
};

export default VerifyEmail;