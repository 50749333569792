import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import Client_ from '../../api/Client';
import { useNotification } from '../../stores/actions/StoreActions';

interface ChatMessage {
    role: string;
    text: string;
    timestamp: string;
}

const starterQuestions = [
    'What is your most impressive accomplishment?',
    'What makes you different from other engineers?',
    'Can you tell me a bit about yourself?',
    'Can you summarize your career for me?',
    'What are your strengths and weaknesses?',
    'Why are you uniquely qualified to join my company?',
]

const SaarimChat: React.FC = () => {
    const [message, setMessage] = useState('');
    const [chatHistory, setChatHistory] = useState([{}]);
    const [isLoading, setIsLoading] = useState(false);
    const { addNotificationError, addNotificationSuccess } = useNotification();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        document.title = `Saarim Chat`;
    }, []);

    const sendChatMessage = async (query: any) => {
        setIsLoading(true);

        const chatHistoryCopy = [...chatHistory];
        setMessage('');

        chatHistory.push({
            role: 'user',
            text: query,
            timestamp: new Date().toISOString(),
        })

        if (chatHistory.length === 2 && Object.keys(chatHistory[0]).length === 0) {
            chatHistory.shift();
        };

        Client_.client_().askSaarim(query, chatHistoryCopy).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                setChatHistory(res?.result?.chat_history)
            }
        });
    };

    const handleButtonClick = (text: any) => {
        setMessage(text);
        sendChatMessage(text);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh', overflow: 'hidden' }}>
            <h1>AI Chat with Saarim</h1>

            {chatHistory && Object.keys(chatHistory[0]).length === 0 && (
                <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column-reverse', maxWidth: '500px', justifyContent: 'center' }}>
                    {starterQuestions.map((question, index) => (
                        <Button
                            onClick={() => handleButtonClick(question)}
                            style={{ marginBottom: 10 }}
                            variant="contained" color="primary"
                            key={index}
                        >
                            {question}
                        </Button>
                    ))}
                </div>
            )}

            <div style={{
                marginTop: 10,
                height: 'calc(100vh - 200px)', // Adjusted height calculation
                width: '100%',
                overflow: 'auto', // Changed to 'auto' instead of 'scroll'
                display: 'flex',
                flexDirection: 'column'
            }}>
                {chatHistory && Object.keys(chatHistory[0]).length > 0 && chatHistory.map((chatMessage: any, index: number) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', bgcolor: chatMessage.role === 'user' ? '#202020' : '#616160', py: 1 }}>
                        <Box sx={{ minWidth: '150px', mr: 2, flexShrink: 0, justifyContent: 'flex-end', textAlign: 'right' }}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold', display: 'inline-block', marginRight: 2 }}>
                                {chatMessage.role === 'user' ? 'Me' : 'SaarimGPT'}
                            </Typography>
                        </Box>
                        <Box sx={{ maxWidth: '75%', width: '100%', textAlign: 'left' }}>
                            <Typography variant="body1" sx={{ display: 'inline-block', whiteSpace: 'pre-line' }}>
                                {chatMessage.text}
                            </Typography>
                        </Box>
                    </Box>
                ))}
            </div>

            <div style={{
                position: 'fixed',
                bottom: 5,
                left: 5,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                padding: '10px',
                backgroundColor: 'background.paper' // Add a background to the input area
            }}>
                <Tooltip title="Clear chat">
                    <IconButton onClick={() => setChatHistory([{}])}>
                        <ClearIcon />
                    </IconButton>
                </Tooltip>


                <TextField
                    style={{ width: '75%' }}
                    id="outlined-basic"
                    label="Send a message..."
                    variant="outlined"
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            sendChatMessage(message);
                        }
                    }}
                />

                {isLoading ? (
                    <CircularProgress sx={{ marginTop: 1, marginLeft: 2 }} />
                ) : (
                    <IconButton color="primary" onClick={() => sendChatMessage(message)}>
                        <SendIcon />
                    </IconButton>
                )}
            </div>
        </div>
    );
};

export default SaarimChat;
