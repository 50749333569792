import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Client_ from '../../api/Client';
import { useNotification } from '../../stores/actions/StoreActions';

interface PromptHelperProps { }


const PromptHelper: React.FC<PromptHelperProps> = () => {

    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const { addNotificationError, addNotificationSuccess, addNotification } = useNotification()
    const dispatch = useDispatch();


    const [task, setTask] = useState("");
    const [generatedPrompts, setGeneratedPrompts] = useState("");
    const [promptCritique, setPromptCritique] = useState("");
    const [improvedPrompts, setImprovedPrompts] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        document.title = 'Prompt Helper';
    }, []);

    const generatePrompt = () => {
        setIsLoading(true);
        setGeneratedPrompts("");
        setPromptCritique("");
        setImprovedPrompts("");
        Client_.client_().generatePrompt(task).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                setGeneratedPrompts(res?.result?.prompt);
            }
        });
    };

    const improvePrompt = () => {
        setIsLoading(true);
        addNotification('Improving prompt... this may take a few seconds.');
        Client_.client_().improvePrompt(generatedPrompts, task).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                setImprovedPrompts(res?.result?.improved_prompt);
                setPromptCritique(res?.result?.critique);
            }
        });
    };


    const handleCopyText = () => {
        navigator.clipboard.writeText(generatedPrompts);
        addNotificationSuccess("Copied to clipboard");
    };

    const handleCopyImprovedText = () => {
        navigator.clipboard.writeText(improvedPrompts);
        addNotificationSuccess("Copied to clipboard");
    };


    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h1>LLM Prompt Helper</h1>
            <p>Enter a task to generate a few prompts that can be fed to an LLM to achieve that task.</p>
            <TextField
                style={{ width: '50%' }}
                id="outlined-basic"
                label="Task"
                variant="outlined"
                value={task}
                multiline
                onChange={(event) => setTask(event.target.value)}
                helperText="Enter a task you want to do, e.g. 'design a product'"
            />

            {isLoading ?
                <CircularProgress />
                :
                <Button variant="contained" color="primary" onClick={() => generatePrompt()} startIcon={<EmojiObjectsIcon />} sx={{mt: 2}} >
                    Generate Prompt
                </Button>
            }

            {generatedPrompts &&
                <>
                    {/* <p style={{ marginTop: 10 }}>Here are your generated prompts:</p> */}
                    {/* <TextareaAutosize
                    style={{ width: '75%', padding: '8px', color: 'white', backgroundColor: 'inherit', fontSize: '16px', margin: '20px 0 0 0' }}
                        minRows={3}
                        placeholder="Empty"
                        value={generatedPrompts}
                    /> */}
                    <div style={{ display: 'flex', flexDirection: 'row', width: '90%', justifyContent: 'center', marginTop: 5 }}>
                        <TextField
                            // sx={{ width: '75%', padding: '8px', color: 'white', backgroundColor: 'inherit', fontSize: '16px', margin: '20px 0 0 0' }}
                            sx={{ width: improvedPrompts ? '50%' : '75%', padding: '8px', color: 'white', backgroundColor: 'inherit', fontSize: '16px', margin: '20px 0 0 0', '@media (max-width: 480px)': { width: '100%', fontSize: '14px' } }}
                            multiline
                            label={improvedPrompts ? 'Original Prompt' : 'Generated Prompt'}
                            placeholder="Empty"
                            value={generatedPrompts}
                            onChange={(event) => setGeneratedPrompts(event.target.value)}
                        />
                        {improvedPrompts &&
                            <TextField
                                // sx={{ width: '75%', padding: '8px', color: 'white', backgroundColor: 'inherit', fontSize: '16px', margin: '20px 0 0 0' }}
                                sx={{ width: '50%', padding: '8px', color: 'white', backgroundColor: 'inherit', fontSize: '16px', margin: '20px 0 0 0', '@media (max-width: 480px)': { width: '100%', fontSize: '14px' } }}
                                multiline
                                placeholder="Empty"
                                label="Improved Prompt"
                                value={improvedPrompts}
                                onChange={(event) => setImprovedPrompts(event.target.value)}
                            />
                        }
                    </div>

                    <div style={{ display: 'flex', gap: 2, flexDirection: 'row' }}>
                        <Button
                            sx={{ marginTop: 2, '@media (max-width: 480px)': { width: '100%', fontSize: '14px' } }}
                            variant="contained"
                            startIcon={<FileCopyIcon />}
                            onClick={handleCopyText}
                        >
                            {improvedPrompts ? 'Copy Original Prompt' : 'Copy'}
                        </Button>

                        {improvedPrompts &&
                            <Button
                                sx={{ marginTop: 2, '@media (max-width: 480px)': { width: '100%', fontSize: '14px' }, ml: 2 }}
                                variant="contained"
                                startIcon={<FileCopyIcon />}
                                onClick={handleCopyImprovedText}
                            >
                                Copy Improved Prompt
                            </Button>
                        }

                        {false ?
                            <CircularProgress size='4vh' sx={{ ml: 2, mt: 2 }} />
                            :
                            <Button
                                sx={{ marginTop: 2, '@media (max-width: 480px)': { width: '100%', fontSize: '14px' }, ml: 2 }}
                                variant="contained"
                                startIcon={<AutoFixHighIcon />}
                                onClick={() => improvePrompt()}
                                // disabled={improvedPrompts.length > 0 || isLoading}
                            >
                                Improve Prompt
                            </Button>
                        }
                    </div>
                </>
            }

            {promptCritique &&
                <>
                    <h2 style={{ marginTop: 30 }}>Evaluation of Prompts</h2>
                    <TextField
                        sx={{ width: '75%', padding: '8px', color: 'white', backgroundColor: 'inherit', fontSize: '16px', margin: '20px 0 0 0', '@media (max-width: 480px)': { width: '100%', fontSize: '14px' } }}
                        multiline
                        placeholder="Empty"
                        value={promptCritique}
                    />
                </>
            }
        </div>
    );
};

export default PromptHelper;