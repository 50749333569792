
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LaunchIcon from '@mui/icons-material/Launch';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import RepeatIcon from '@mui/icons-material/Repeat';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Fab from '@mui/material/Fab';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import LazyLoad from 'react-lazyload';
import { useNavigate, useSearchParams } from "react-router-dom";
import Client_ from '../../api/Client';
import constants from '../../constants/Constants';
import { useNotification } from '../../stores/actions/StoreActions';
import utils from '../../utils';
import HelpText from '../HelpText/HelpText';
import Link from '../Link';
import SwipeableTextMobileStepper from '../SwipeableTextMobileStepper/SwipeableTextMobileStepper';

interface Tweet {
    date: string;
    followers: number;
    id: number;
    images: string[];
    likes: number;
    norm_followers: number;
    norm_score: number;
    rank: number;
    retweets: number;
    safe_user: string;
    score: number;
    score_by_followers: number;
    text: string;
    url: string;
    user: string;
}

interface TweetGalleryProps {

}



const selectOptions = [
    "best", "hot", "top", "new",
    "worst",
    "random", "random_user", 'random_hot', 'random_best', 'random_top',
    "user", "popularity",

];
const lookbackOptions = ['today', 'week', 'month', 'year', 'all'];


const TweetGallery: React.FC<TweetGalleryProps> = () => {
    // const [tweets, setTweets] = useState<Tweet[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const { addNotificationError, addNotification } = useNotification()
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const isFirstRender = useRef(true);
    // const history = useHistory();

    const [tweets, setTweets] = useState<Tweet[]>([]);
    const [includeImages, setIncludeImages] = useState<boolean>(true);
    const [includeTweets, setIncludeTweets] = useState<boolean>(true);
    const [lookback, setLookback] = useState<string>('all');
    const [selected, setSelected] = useState<string>('best');

    const [username, setUsername] = useState<string>('')
    const [user, setUser] = useState<string>('')
    const [users, setUsers] = useState<string[]>([])
    const [openModal, setOpenModal] = useState(false);
    const [upperBound, setUpperBound] = useState<string>('2');
    const [lowerBound, setLowerBound] = useState<string>('1');
    const [useMediaGallery, setUseMediaGallery] = useState<boolean>(false);
    const [items, setItems] = useState(tweets);
    const [sliceIndex, setSliceIndex] = useState(20);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        // setItems(tweets);
        // set items to first 20 results
        setItems(tweets.slice(0, 20));
    }, [tweets]);

    const getRankedTweetsForUsers = (usernames: string[], includeTweets: boolean = true, includeImages: boolean = true, lookback: string = '', selected: string = '', upperBound: string = '', lowerBound: string = '') => {
        document.title = `@${usernames.join(', @')} | Tweet Ranker`;
        addNotification(`Getting tweets for @${usernames.join(', @')}... this may take a few seconds.`)
        setIsLoading(true);
        let newParams = {
            user: usernames.join(','),
            tweets: includeTweets.toString(),
            images: includeImages.toString(),
            lookback: lookback,
            selected: selected,
            gallery: useMediaGallery.toString(),
            lower: lowerBound,
            upper: upperBound
        }
        setSearchParams(newParams);
        Client_.client_().getRankedTweetsForUsers(usernames, includeTweets, includeImages, lookback, selected, upperBound, lowerBound).then(({ err, res }) => {
            setIsLoading(false);
            if (err || !res?.success) {
                addNotificationError(err)
                return;
            } else {
                setTweets(res?.result?.tweets);
            }
        });
    };

    const handleAddUser = (event: any) => {
        let newUser = event.target.value.trim();
        newUser = utils.getTwitterUserFromUrl(newUser);
        if (newUser !== "" && !users.includes(newUser)) {
            setUsers([...users, newUser]);
            event.target.value = "";
        } else if (users.includes(newUser)) {
            event.target.value = "";
        }
        if (event.key === 'Enter' && event.metaKey && users.length > 0) {
            saveAndSearch();
        }
    };

    const handleDeleteUser = (userToDelete: string) => {
        const updatedUsers = users.filter((user) => user !== userToDelete);
        setUsers(updatedUsers);
    };

    const saveAndSearch = () => {
        setOpenModal(false);
        if (users.length > 0) {
            getRankedTweetsForUsers(users, includeTweets, includeImages, lookback, selected, upperBound, lowerBound);
        }
    };

    const tweetCardContent = (tweet: Tweet) => {
        return (
            <CardContent>
                <Typography gutterBottom variant="h6" component="h3">
                    <Link to={`/twitter?user=${tweet.user}`} target="_blank"> @{tweet.user}</Link>
                    <Tooltip title={utils.getHumanReadableDate(tweet.date)}>
                        <span style={{ marginLeft: 5, fontSize: 12, color: 'gray' }}>
                            {utils.getTimeElapsed(tweet.date)}
                        </span>
                    </Tooltip>
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    {tweet.text}
                </Typography>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 5
                }}>
                    <Typography variant="caption" color="textSecondary" style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Open Profile">
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                <Link to={`/twitter?user=${tweet.user}`} target="_blank" style={{ display: 'flex', alignItems: 'center' }}>
                                    <PersonOutlineIcon style={{ marginRight: 5 }} />
                                </Link>
                            </span>
                        </Tooltip>
                    </Typography>

                    <Typography variant="caption" color="textSecondary" style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Rank">
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                <StarBorderIcon />
                                <span style={{ marginLeft: 5 }}>{tweet.rank}</span>
                            </span>
                        </Tooltip>
                    </Typography>

                    <Typography variant="caption" color="textSecondary" style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Computed Popularity">
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                <TrendingUpIcon style={{ marginRight: 5 }} />
                                <span>{utils.shortNumberFormat(Math.round(tweet.norm_score * 1000))} </span>
                            </span>
                        </Tooltip>
                    </Typography>

                    <Typography variant="caption" color="textSecondary" style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Likes">
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                <FavoriteBorderIcon style={{ marginRight: 5 }} />
                                <span>{utils.shortNumberFormat(tweet.likes)}</span>
                            </span>
                        </Tooltip>
                    </Typography>

                    <Typography variant="caption" color="textSecondary" style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Retweets">
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                <RepeatIcon style={{ marginRight: 5 }} />
                                <span>{utils.shortNumberFormat(tweet.retweets)}</span>
                            </span>
                        </Tooltip>
                    </Typography>

                    <Typography variant="caption" color="textSecondary" style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Open Tweet">
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                <Link to={tweet.url} target="_blank" style={{ display: 'flex', alignItems: 'center' }}>
                                    <LaunchIcon style={{ marginRight: 5 }} />
                                </Link>
                            </span>
                        </Tooltip>
                    </Typography>



                </div>
            </CardContent>
        )
    }

    useEffect(() => {
        let searchUsers = (searchParams.get('user') || '').split(',')
        let searchIncludeTweets = (searchParams.get('tweets') || 'true') === 'true';
        let searchIncludeImages = (searchParams.get('images') || 'true') === 'true';
        let searchSelected = searchParams.get('selected') || 'best';
        let searchMediaGallery = (searchParams.get('gallery') || 'false') === 'true';
        let searchUsersIsNotNull = searchUsers && searchUsers.length > 0 && searchUsers[0] !== '';
        let searchUpper = searchParams.get('upper') || '';
        let searchLower = searchParams.get('lower') || '';
        let searchLookback = searchParams.get('lookback') || 'all';
        if (searchUsersIsNotNull) {
            console.log('searchUsers', searchUsers)
            setUsers(searchUsers);
        }
        setIncludeTweets(searchIncludeTweets);
        setIncludeImages(searchIncludeImages);
        setSelected(searchSelected);
        setUseMediaGallery(searchMediaGallery);
        setUpperBound(searchUpper);
        setLowerBound(searchLower);
        if (searchUsersIsNotNull && isFirstRender.current) {
            isFirstRender.current = false;
        }
        if (!isFirstRender.current && searchUsersIsNotNull) {
            getRankedTweetsForUsers(searchUsers, searchIncludeTweets, searchIncludeImages, searchLookback, searchSelected, searchUpper, searchLower);
        }
        document.title = 'Tweet Ranker';
    }, []);



    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 2, marginRight: 2 }}>
                <p>{constants.twitterDisclaimer}</p>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 10 }}>
                    <div style={{ display: "flex", alignItems: "center", marginTop: 15, flexDirection: 'column', width: '100%' }}>
                        <div style={{ display: "flex", alignItems: "center", width: 'auto' }}>
                            <TextField
                                label="Twitter Handle(s)"
                                variant="outlined"
                                placeholder="Add a user"
                                helperText='Enter handle(s) or profile URLs. Enter "Joe" for @Joe. Then press "Enter".'
                                onKeyDown={(e) => e.key === "Enter" && handleAddUser(e)}
                                sx={{ ml: 2 }}
                            />
                            <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", marginLeft: 10, width: 'auto', maxWidth: '500px' }}>
                                {users.map((user) => (
                                    <Chip
                                        key={user}
                                        label={user}
                                        onDelete={() => handleDeleteUser(user)}
                                        sx={{ margin: "0 5px 5px 0" }}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>

                    <Button variant="contained" color="primary" onClick={() => setOpenModal(true)} sx={{ mr: 2 }}>
                        Search Config
                    </Button>

                    <Dialog open={openModal} onClose={() => setOpenModal(false)}>
                        <DialogTitle>Search Config</DialogTitle>
                        <DialogContent>
                            <FormGroup row>
                                <Tooltip title="Include text based tweets">
                                    <FormControlLabel control={<Checkbox checked={includeTweets} onChange={(e) => setIncludeTweets(e.target.checked)} />} label="Tweets" />
                                </Tooltip>
                                <Tooltip title="Include image based tweets">
                                    <FormControlLabel control={<Checkbox checked={includeImages} onChange={(e) => setIncludeImages(e.target.checked)} />} label="Images" />
                                </Tooltip>
                            </FormGroup>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <FormControl variant="outlined" sx={{ minWidth: 120, m: 2 }}>
                                    <InputLabel >Sort</InputLabel>
                                    <Select
                                        value={selected}
                                        onChange={(event) => setSelected(event.target.value)}
                                        label="Select Option"
                                    >
                                        {selectOptions.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <HelpText helpTextId='twitterSort' />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <FormControl variant="outlined" sx={{ minWidth: 120, m: 2 }}>
                                    <InputLabel>Lookback</InputLabel>
                                    <Select
                                        value={lookback}
                                        onChange={(event) => setLookback(event.target.value)}
                                        label="Select Option"
                                    >
                                        {lookbackOptions.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <HelpText helpTextId='twitterLookback' />
                            </div>
                            {selected === 'popularity' &&
                                <FormGroup row>
                                    {/* <FormControl variant="outlined" sx={{ minWidth: 120, m: 2 }}>
                                        <TextField
                                            label='Lower Bound'
                                            variant="outlined"
                                            type="number"
                                            value={lowerBound}
                                            onChange={(e) => setLowerBound(e.target.value)}
                                        />
                                    </FormControl>
                                    <FormControl variant="outlined" sx={{ minWidth: 120, m: 2 }}>
                                        <TextField
                                            label='Upper Bound'
                                            variant="outlined"
                                            type="number"
                                            value={upperBound}
                                            onChange={(e) => setUpperBound(e.target.value)}
                                        />
                                    </FormControl> */}
                                    <Slider
                                        value={[Number(lowerBound), Number(upperBound)]}
                                        onChange={(e, newValue: any) => {
                                            setLowerBound(String(newValue[0]));
                                            setUpperBound(String(newValue[1]));
                                        }}
                                        min={0}
                                        max={50}
                                        valueLabelDisplay="auto"
                                        marks={[{ value: 0, label: '0' }, { value: 50, label: '50' }, { value: 100, label: '100' },]}
                                    />
                                </FormGroup>
                            }
                            {selected === 'user' &&
                                <FormGroup row>
                                    <FormControl variant="outlined" sx={{ minWidth: 120, m: 2 }}>
                                        <TextField
                                            label='User'
                                            variant="outlined"
                                            value={user}
                                            onChange={(e) => setUser(e.target.value)}
                                        />
                                    </FormControl>
                                </FormGroup>
                            }
                            <FormGroup row>
                                <Tooltip title="Show full size images and do not group images in carousel.">
                                    <FormControlLabel control={<Checkbox checked={useMediaGallery} onChange={(e) => setUseMediaGallery(e.target.checked)} />} label="Use Media Gallery" />
                                </Tooltip>
                            </FormGroup>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenModal(false)} color="primary">
                                Save
                            </Button>
                            <Button onClick={() => saveAndSearch()} color="primary">
                                Save & Search
                            </Button>
                        </DialogActions>
                    </Dialog>

                </div>

                {/* <Typography variant="body2" color="text.secondary" component="p" sx={{ textAlign: 'center', marginBottom: 2 }} >
                    Please be patient, the first time loading tweets for a user may take a while.
                </Typography> */}

                {isLoading ?
                    <CircularProgress sx={{ marginLeft: 2, marginBottom: 3, marginRight: 5, width: '15%' }} />
                    :
                    <Fab sx={{ '@media (max-width: 480px)': { width: '100%', fontSize: '14px' }, marginBottom: 3, marginRight: 3 }} variant="extended" color="primary" size="medium"
                        onClick={() => getRankedTweetsForUsers(users, includeTweets, includeImages, lookback, selected, upperBound, lowerBound)}>
                        Search
                    </Fab>
                }
            </div>



            <div style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                padding: 2
            }}>
                {/*
            TODO: https://www.npmjs.com/package/react-infinite-scroll-component
            */}
                <InfiniteScroll
                    dataLength={items?.length}
                    hasMore={hasMore}
                    loader={items?.length > 0 ? <h4>Loading...</h4> : <></>}
                    endMessage={<p>No more tweets to show.</p>}
                    style={{ display: 'flex', flexWrap: "wrap", justifyContent: 'center', alignItems: 'center' }}
                    next={() => {
                        if (sliceIndex <= tweets.length) {
                            const nextSlice = tweets.slice(sliceIndex, sliceIndex + 20);
                            setItems((prevTweets) => [...prevTweets, ...nextSlice]);
                            setSliceIndex(sliceIndex + 20);
                        }
                        setHasMore(sliceIndex < tweets?.length)
                    }}
                >
                    {items.map((tweet) => (
                        <div key={tweet.id}>
                            {!useMediaGallery ? (
                                <Card sx={{ width: 400, margin: 2, flexGrow: 1 }} key={tweet.id}>
                                    {tweet.images.length > 0 && <SwipeableTextMobileStepper tweetImages={tweet.images} />}
                                    {tweetCardContent(tweet)}
                                </Card>
                            ) : (
                                tweet.images.map((step, index) => (
                                    <LazyLoad once>
                                        <Card sx={{ margin: 2, flexGrow: 1 }} key={tweet.id}>
                                            {step.includes('.mp4') ? (
                                                <Box
                                                    component="video"
                                                    sx={{
                                                        display: 'block',
                                                        // maxWidth: 400,
                                                        overflow: 'hidden',
                                                        width: '100%',
                                                    }}
                                                    src={step}
                                                    controls // add this prop to display video controls
                                                    autoPlay // add this prop to automatically play the video
                                                    muted // add this prop to mute the video (optional)
                                                    loop // add this prop to enable continuous loop (optional)
                                                />) : (
                                                <Box
                                                    component="img"
                                                    sx={{
                                                        display: 'block',
                                                        // maxWidth: 400,
                                                        overflow: 'hidden',
                                                        width: '100%',
                                                    }}
                                                    src={step}
                                                />
                                            )}
                                            {tweetCardContent(tweet)}
                                        </Card>
                                    </LazyLoad>
                                )))}
                        </div>
                    ))}
                </InfiniteScroll>
            </div>
        </div >
    );
};

export default TweetGallery;
