import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useUser } from '../../stores/actions/StoreActions';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


import Link from '../Link';
import './Login.css';

interface LoginProps { }

const Login: React.FC<LoginProps> = () => {
    let navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const { loginUser } = useUser();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = () => {
        loginUser(email, password);
    };

    useEffect(() => {
        if (user?.username) {
            navigate('/profile');
        }
    }, [navigate, user]);


    return (
        <div className='login' >
            <h1>Log in to continue</h1>
            <TextField
                style={{ width: '50%' }}
                id="outlined-basic"
                label="Email"
                variant="outlined"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
            />

            <TextField
                style={{ marginTop: 20, width: '50%' }}
                id="outlined-basic"
                label="Password"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                InputProps={{ // this is where the toggle button is added
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            <Link to='/password-reset' sx={{marginTop: 2}}>Forgot your password?</Link>

            {user.isLoading ?
                <CircularProgress sx={{marginTop: 2}}/>
                :
                <Fab style={{ marginTop: 20, width: '15%' }} variant="extended" color="primary" size="medium" onClick={() => handleSubmit()}>Log in</Fab>}
            <Divider style={{ marginTop: 20, width: '50%' }} />
            <p style={{ marginTop: 20, textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}>Don't have an account?</p>
            <Link to="/register" style={{ textDecoration: 'none' }}>
                {/* change the button width to match the text width */}
                <Fab variant="extended" color="secondary" size="medium" >Sign Up</Fab>
            </Link>
        </div>
    );
};

export default Login;